import { mapGetters } from "vuex";
import { postAPICall } from "../helpers/httpHelper";
let payVariables = require("../components/templates/DynamicPaymentVariables.json");
export default {
  data() {
    return {
      loading:false,
      LoadingText:'',
      elements:[],
      mapFieldsToDocTemplateModel:false,
      actionDocTemplates:[],
      entityIDForDocTemp: "",
      selectMail:[],
      docTemplatesData:[],
      allFields: [],
      actionEntityFields: {},
      operators: {
        AUTO_INCREMENT_NUMBER: [
          {
            label: "Generate new number",
            key: "GENERATE_NEW_NUMBER",
          },
        ],
        NUMBER: [
          {
            label: "Value (=)",
            key: "=",
          },
          {
            label: "Subtract (-)",
            key: "-",
          },
          {
            label: "Add (+)",
            key: "+",
          },
        ],
        CURRENCY: [
          {
            label: "Value (=)",
            key: "=",
          },
          {
            label: "Subtract (-)",
            key: "-",
          },
          {
            label: "Add (+)",
            key: "+",
          },
        ],
      },
      stepRefresh: false,
      stepRefreshIndex: "",
      allFieldsWithDataTable: [],
      actionFormbuilderSteps: [],
      allCurrentEntityFields: [],
    };
  },
  computed: {
    ...mapGetters("entities", ["getEntityDataById", "getAllEntities"]),
    ...mapGetters("automationPipeline", [
      "getCreateNewPipeline",
      "getUpdatePipelineStatus",
    ]),
    ...mapGetters("filters", ["getAllEntityFilters"]),
    ...mapGetters("entityRelationships", ["getMultipleEntityRelationships"]),
    checkFilterDisable() {
      if (this.dataTablefilters.length) {
        let emptyFilter = this.dataTablefilters.find((e) => {
          if (!e.selectField || !e.operator) {
            return true;
          }
          if (
            e.operator != "exists" &&
            e.operator != "notexists" &&
            e.data_type != "BOOLEAN"
          ) {
            if (e.data_source == "TEMPLATE" && !e.value_field) {
              return true;
            } else if (
              e.data_source !== "TEMPLATE" &&
              (e.value == null || e.value == undefined || e.value == "") &&
              typeof e.value != "number"
            ) {
              return true;
            }
          }
        });
        return emptyFilter ? true : false;
      }
      return false;
    },
    checkDisabled() {
      if (this.newFile.type) {
        if (this.newFile.type == "FILE") {
          return !this.newFile.files || !this.newFile.files.length;
        }
        return !this.newFile.name && !this.newFile.name_mapped_with
          ? true
          : this.checkLockFolderDisabled(this.newFile);
      }
      return true;
    },
  },
  methods: {
    getUniqueOtherAddActions(actions) {
      const uniqueActions = new Set(actions.map((arr) => JSON.stringify(arr)));
      return Array.from(uniqueActions).map(JSON.parse);
    },
    checkLockFolderDisabled(newFile) {
      if (
        newFile?.permission_settings?.lock_folder &&
        !newFile?.approval_settings?.approval_folder
      ) {
        return (
          !newFile?.permission_settings?.type ||
          !newFile?.permission_settings?.application_users.length ||
          typeof this.newFile?.permission_settings?.value !== "number"
        );
      }
      if (newFile?.approval_settings?.approval_folder) {
        return (
          !newFile?.approval_settings?.requesting_users.length ||
          !newFile?.approval_settings?.approver_type ||
          !newFile?.approval_settings?.approver_user_ids.length ||
          (newFile?.approval_notify_settings?.notify_user &&
            (!newFile?.approval_notify_settings?.notify_user_type ||
              (!newFile?.approval_notify_settings?.notify_user_ids.length &&
                !newFile?.approval_notify_settings?.notify_users.length &&
                !newFile?.approval_notify_settings?.notify_user_fields
                  .length) ||
              !newFile?.approval_notify_settings?.mail_template_data?.subject ||
              !newFile?.approval_notify_settings?.mail_template_data?.body))
        );
      }
      return false;
    },
    getTypeFromInputType(inputType) {
      const obj = {
        SINGLE_LINE_TEXT: "STRING",
        MULTI_LINE_TEXT: "STRING",
        DATE: "DATE",
        DATE_TIME: "DATE_TIME",
        TIME: "TIME",
        NUMBER: "NUMBER",
        AGGREGATE_FUNCTION: "NUMBER",
        FILE: "FILE",
        SELECT: "ARRAY",
        YES_OR_NO: "BOOLEAN",
        MULTI_SELECT: "ARRAY",
        CHECKBOX_GROUP: "ARRAY",
        RADIO_BUTTON_GROUP: "ARRAY",
        CHECKBOX: "BOOLEAN",
        RADIO: "BOOLEAN",
        LIST: "ARRAY",
        HEADING: "STRING",
        SIGNATURE: "STRING",
        GLOBAL_VARIABLE: "OBJECT_ID",
        ENTITY: "OBJECT_ID",
        IMAGE: "STRING",
        PASSWORD: "STRING",
        MASKED: "STRING",
        EMAIL: "EMAIL",
        PHONE: "NUMBER",
        FAX: "NUMBER",
        CURRENCY: "NUMBER",
        ZIP_CODE: "NUMBER",
        PHONE_COUNTRY_CODE: "PHONE",
        QUESTION: "STRING",
        DATE_TIME_RANGE: "DATE_TIME_RANGE",
        DATE_RANGE: "DATE_RANGE",
        WEEKDAYS: "WEEKDAYS",
        TIME_RANGE: "TIME_RANGE",
        ACTION_BUTTON: "ACTION",
        HYPER_LINK: "STRING",
        STAR_RATING: "NUMBER",
        PAY_BUTTON: "PAYMENT",
        DATA_TABLE: "TABLE",
        AUTO_INCREMENT_NUMBER: "STRING",
      };

      return obj[inputType];
    },
    getSelectOptions(filter, fields) {
      let field = fields.find((e) => e.key == filter.selectField);
      if (field?.options) {
        return field.options;
      }
      return [];
    },
    checkCurrentFieldData(fieldKey, filter, fields) {
      let field = fields.find((e) => e.key == fieldKey);
      if (field?.inputType == "FORMULA") {
        filter.data_type = this.getTypeFromInputType(field.result_type);
      } else {
        filter.data_type = this.getTypeFromInputType(field.inputType);
      }
      filter.inputType = field.inputType;
    },
    allowedDataSources(filter) {
      let data_sources = ["VALUE"];
      if (filter?.data_type !== "RELATION") {
        data_sources.push("TEMPLATE");
      }
      if (filter?.inputType == "SELECT") {
        data_sources.push("OPTIONS");
      }
      return data_sources;
    },
    getCurrentFieldOperators(filter) {
      const numericOperators = [
        {
          title: "Equals (=)",
          value: "=",
        },
        {
          title: "Greater Than (>)",
          value: ">",
        },
        {
          title: "Greater Than or Equal (>=)",
          value: ">=",
        },
        {
          title: "Less Than (<)",
          value: "<",
        },
        {
          title: "Less Than or Equal (<=)",
          value: "<=",
        },
        {
          title: "In",
          value: "in",
        },
        {
          title: "Not In",
          value: "nin",
        },
        {
          title: "Exist",
          value: "exists",
        },
        {
          title: "Not Exist",
          value: "notexists",
        },
      ];

      const operators = {
        NUMBER: numericOperators,
        CURRENCY: numericOperators,
        SELECT: [
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
        ],
        CHECKBOX: [
          {
            title: "Checked",
            value: "checked",
          },
          {
            title: "Unchecked",
            value: "unchecked",
          },
        ],
      };
      return operators[filter.inputType] || [];
    },
    getDataTableFields(allFieldsWithDataTable) {
      let allowedFields = [
        "NUMBER",
        "CURRENCY",
        "FORMULA",
        "AGGREGATE_FUNCTION",
        "CHECKBOX",
      ];
      return allFieldsWithDataTable
        .filter(
          (e) =>
            e.key &&
            e.key.includes(":") &&
            allowedFields.indexOf(e.inputType) !== -1
        )
        .map((dt) => {
          let e = JSON.parse(JSON.stringify(dt));
          e.key = e.key.split(":")[1];
          e.data_type = this.getTypeFromInputType(e.result_type);
          return e;
        });
    },
    hideFieldsLabels(fields) {
      return fields.map((data) => {
        if (data?.properties) {
          data.properties = {
            ...data.properties,
            ...{
              ["hideLabel"]: true,
            },
          };
        } else {
          data["properties"] = {
            ["hideLabel"]: true,
          };
        }
        data["allow_quick_add"] = false;
        return data;
      });
    },
    addFilter() {
      this.dataTablefilters.push({});
    },
    removeFilter(index) {
      this.dataTablefilters.splice(index, 1);
    },
    addDataFilter(step) {
      if (step?.keyIndex) {
        this.dataTablefilters = step.data_table_filters || [];
        this.selectedDatatableFilterStep = step.keyIndex;
        this.addDataTableStepFilters = true;
      }
    },
    resetDataTableFilters() {
      this.dataTablefilters = [];
      this.dataTablefiltersQuery = "AND";
      this.selectedDatatableFilterStep = "";
      this.addDataTableStepFilters = false;
    },
    saveDataTableFilters() {
      this.upateNode(
        this.selectedDatatableFilterStep,
        "data_table_filters",
        this.dataTablefilters
      );
      this.resetDataTableFilters();
    },
    getRelatedFields(filter) {
      return this.allFieldsWithDataTable.filter((e) => {
        let data_type = this.getTypeFromInputType(e.inputType || e.result_type);
        if (filter.data_type == data_type) {
          return true;
        }
      });
    },
    getMatchedTypeEntityFields(filter) {
      let fields = this.getDataTableFields(this.allFieldsWithDataTable);
      return fields.filter((e) => {
        if (filter.data_type == e.data_type && e.data_type == "NUMBER") {
          return true;
        }
      });
    },
    savePrimarymapTemplate() {
      this.upateNode(
        this.selectedNodeIndex,
        "primary_data",
        this.actionEntityPrimaryFieldsMap
      );
      this.upateNode(
        this.selectedNodeIndex,
        "fields_operators",
        this.actionEntityFieldsOparatorMap
      );
      this.upateNode(
        this.selectedNodeIndex,
        "fields_operations",
        this.actionEntityFieldsOparationsMap
      );
      this.upateNode(
        this.selectedNodeIndex,
        "dataTableConfig",
        this.dataTableConfigObject
      );
      this.resetPrimarymapTemplate();
    },
    saveEmailConfigurations() {
      this.upateNode(this.selectedNodeIndex, "email_object", this.email_object);
    },
    saveWhatsappConfigurations() {
      this.upateNode(
        this.selectedNodeIndex,
        "whatsapp_object",
        this.whatsapp_object
      );
    },
    saveAutomationHelper() {
      this.upateNode(
        this.selectedNodeIndex,
        "automation_rules",
        this.automationRules
      );
      this.upateNode(
        this.selectedNodeIndex,
        "automationRulesType",
        this.automationRulesType
      );
      this.resetAutomationRules();
    },
    resetPrimarymapTemplate() {
      if (this.checkSaveDisable) {
        this.upateNode(this.selectedNodeIndex, "entity_from", "");
        this.upateNode(this.selectedNodeIndex, "entity_id", "");
      }
      this.selectedNodeIndex = "";
      this.actionEntityPrimaryFieldsMap = {};
      this.actionEntityFieldsOparatorMap = {};
      this.actionEntityFieldsOparationsMap = {};
      this.actionEntityPrimaryFields = [];
      this.actionEntityTemplates = [];
      this.currentStep = null;
      this.mapPrimaryFieldsModal = false;
      this.actionFormbuilderSteps = [];
      this.actionEntityPrimaryFieldsMap = {};
      this.mapFieldsToFormbuilderModel = false;
      this.dataTableConfigObject = null;
    },
    resetAutomationRules() {
      this.automationRules = [];
      this.automationRulesType = true;
      this.configAutomateModel = false;
    },
    openDeleteFolderCofigureModal(row) {
      this.deleteFolder = true;
      this.selectedNodeIndex = row.keyIndex;
      this.currentStep = row;
      this.mapFolderCofigureModal = true;
      this.deleteActionEntityFoldersList = row.delete_folders_list || {
        type: "ROOT",
        query_on: "CREATED",
        query_type: "YEAR",
        query_duration: 1,
      };
    },
    openFolderCofigureModal(row) {
      this.deleteFolder = false;
      this.selectedNodeIndex = row.keyIndex;
      this.currentStep = row;
      this.mapFolderCofigureModal = true;
      this.actionEntityFoldersList = row.folders_list || [];
      this.actionEntityFoldersListFolder = row.create_on_folder || "";
      this.actionEntityFoldersListType = this.form.is_global_action ? "ROOT" : "CURRENT_DATA";
    },
    resetFolderCofigureTemplate() {
      if (this.deleteFolder) {
        this.upateNode(
          this.selectedNodeIndex,
          "delete_folders_list",
          this.deleteActionEntityFoldersList
        );
      } else {
        this.upateNode(
          this.selectedNodeIndex,
          "folders_list",
          this.actionEntityFoldersList
        );
        this.upateNode(
          this.selectedNodeIndex,
          "create_on_folder",
          this.actionEntityFoldersListFolder
        );
        this.upateNode(
          this.selectedNodeIndex,
          "folder_type",
          this.actionEntityFoldersListType
        );
      }
      this.selectedNodeIndex = "";
      this.currentStep = null;
      this.actionEntityFoldersList = [];
      this.actionEntityFoldersListFolder = "";
      this.actionEntityFoldersListType = this.form.is_global_action ? "ROOT" : "CURRENT_DATA";
      this.deleteActionEntityFoldersList = {
        type: "ROOT",
        query_on: "CREATED",
        query_type: "YEAR",
        query_duration: 1,
      };
      this.deleteFolder = false;
      this.mapFolderCofigureModal = false;
    },
    isImage(file) {
      const imageTypes = /^image\/(jpeg|png|gif|bmp|svg\+xml|jpeg)$/; // Regular expression to match image MIME types
      return imageTypes.test(file);
    },
    openNew() {
      this.openNewFileModal = true;
    },
    onDelete(i) {
      this.$confirm("Are you sure to delete?", "Warning", {
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.actionEntityFoldersList.splice(i, 1);
      });
    },
    resetModal() {
      this.openNewFileModal = false;
      this.newFile.path = "";
      this.newFile.file = "";
      this.newFile.name = "";
      this.newFile.file_format = "";
      this.newFile.entityRelationships = [];
      this.newFile.sharedList = [];
      this.newFile.name_mapped_with = "";
      this.newFile.type = "FOLDER";
    },
    saveFolder() {
      this.actionEntityFoldersList.push(
        JSON.parse(JSON.stringify(this.newFile))
      );
      this.resetModal();
    },
    async openPrimaryFieldsModal(row) {
      this.selectedNodeIndex = row.keyIndex;
      this.actionEntityPrimaryFieldsMap = {};
      this.actionEntityFieldsOparatorMap = {};
      this.actionEntityFieldsOparationsMap = {};
      this.actionEntityPrimaryFields = [];
      this.actionEntityTemplates = [];
      this.dataTableConfigObject = null;
      this.currentStep = row;
      if (row?.primary_data) {
        this.actionEntityPrimaryFieldsMap = row.primary_data;
        if (row?.fields_operators) {
          this.actionEntityFieldsOparatorMap = row.fields_operators;
        }
        if (row?.fields_operations) {
          this.actionEntityFieldsOparationsMap = row.fields_operations;
        }
      }
      this.actionEntityPrimaryFields = this.getFieldProperty(
        this.allEntities,
        row.entity_id,
        "_id",
        "primaryFields",
        []
      );
      this.actionEntityTemplates = this.getFieldProperty(
        this.allEntities,
        row.entity_id,
        "_id",
        "templates",
        []
      ).flatMap((e) => e.template_id);
      if (row.action == "other/AUTOMATE" || row.action == "other/ADD") {
        let ids = this.allFieldsWithDataTable
          .filter((e) => e.input_type == "ENTITY")
          .map((e) => e.entity_id);
        await this.$store.dispatch(
          "entityRelationships/fetchMultipleEntityRelationships",
          { ids: ids }
        );
        this.fillWithRelationVisible = true;
      } else {
        this.fillWithRelationVisible = false;
      }
      this.actionEntityPrimaryFields = this.actionEntityPrimaryFields.map(
        (e) => {
          if (e.key && !e.key.includes("#")) {
            e.key = e.template_id + "#" + e.key;
          }
          return e;
        }
      );
      if (row.dataTableConfig) {
        this.dataTableConfigObject = row.dataTableConfig;
      } else {
        this.prepareDataTableConfigObject();
      }
      this.mapPrimaryFieldsModal = true;
    },
    prepareDataTableConfigObject() {
      this.selectedEntityTableFields = [];
      //has to optimize this
      let tempObj = {};
      (this.allTemplates || []).forEach((template) => {
        tempObj[template._id] = {
          name: template.name,
          id: template._id,
          fields: this.getFieldsFromTemplate(template, false, false),
        };
      });
      this.actionEntityTemplates.map((tempId) => {
        let fields = tempObj[tempId]?.fields?.filter(
          (fd) => fd.inputType == "DATA_TABLE"
        );
        this.selectedEntityTableFields = [
          ...this.selectedEntityTableFields,
          ...fields,
        ];
      });
      this.dataTableConfigObject = {};
      this.selectedEntityTableFields.map((table) => {
        this.dataTableConfigObject[table.key] = {
          selected_type: "",
          selected_data_table_key: "",
        };
      });
    },
    getAllEntityFields(entity_id) {
      let entity = this.allEntities.find((e) => e._id == entity_id);
      let fields = [];
      if (entity) {
        entity.templates.forEach((tem) => {
          fields = [...fields, ...this.getFieldsFromTemplate(tem.templateInfo)];
        });
      }
      return fields;
    },
    saveMappingFields() {
      this.entity_formbuilder_configurations[this.mappingIndex][
        "primary_data"
      ] = JSON.parse(JSON.stringify(this.actionEntityPrimaryFieldsMap));
      this.mappingIndex = -1;
      this.isFormbuilderMappingEdit = false;
    },
    async openDocumentTemplateFieldsConfigModal(temp,i){
      try{
        this.mappingIndex = i;
        if (!temp?.doc_template_id) {
          this.$notify.warning({
            title: "Warning",
            message: "Please Select Document Template",
          });
          return;
        }
        this.actionDocTemplates = []
        this.elements = this.docTemplatesData.filter((e)=> e._id === temp.doc_template_id)
        console.log(this.elements,'ooooooooo')
        this.mapFieldsToDocTemplateModel = true;
      }catch(err){
        console.log(err)
      }
    },
    async openFormbuilderPrimaryFieldsModal(row, i) {
      this.isFormbuilderMappingEdit = true;
      this.mappingIndex = i;
      if (!row?.formbuilder_id) {
        this.$notify.warning({
          title: "Warning",
          message: "Please select form builder",
        });
        return;
      }
      this.actionFormbuilderSteps = [];
      this.actionEntityPrimaryFieldsMap = {};
      if (row?.primary_data) {
        this.actionEntityPrimaryFieldsMap = row.primary_data;
      }
      this.actionFormbuilderSteps = this.getFormbuilderAllFields(
        row.formbuilder_id
      );
      this.mapFieldsToFormbuilderModel = true;
    },
    openSendFormbuilderPriorityModal(row) {
      this.selectedNodeIndex = row.keyIndex;
      this.entity_formbuilder_configurations =
        row?.entity_formbuilder_configurations || [];
      this.is_enforce_order = row.is_enforce_order;
      this.openFormbuilderPriorityDialog = true;
    },
    async openConfigureAndSendDocTemplateModal(step){
      this.selectedNodeIndex = step.keyIndex;
      this.doc_template_configuration = step?.doc_template || this.doc_template_configuration
      this.openDocumentTemplateDialog = true
      await this.fetchDocTemplatesUsingEntityId()
    },
    saveDocTemplateConfiguration(){
      this.upateNode(this.selectedNodeIndex, "doc_template", this.doc_template_configuration)
      this.resetSendDocumentTemplateDialog();
    },
    resetSendDocumentTemplateDialog() {
      this.openDocumentTemplateDialog = false;
      this.mapFieldsToDocTemplateModel = false
    },
    resetMappingDocumentTemplateDialog(){
      this.mapFieldsToDocTemplateModel = false
    },
    async fetchDocTemplatesUsingEntityId(){
      try{
        this.loading = true;
        this.LoadingText = 'Please wait, fetching document templates...'
        this.entityIDForDocTemp = this.$route.params.entity_id
        let response =  await postAPICall(
          "GET",
          `/doc_temp/${this.entityIDForDocTemp}`
        );
        this.docTemplatesData = response.data
        this.loading = false;
    }catch(err){
        this.loading = false;
        console.log(err)
      }
    },
    removeFormbuilderData(index) {
      this.entity_formbuilder_configurations.splice(index, 1);
    },
    addNewFormbuilderEntityConfig() {
      const newRow = {
        selected_entity: "",
        formbuilder_id: "",
        primary_data: {},
        order: -1,
      };
      this.entity_formbuilder_configurations.push(newRow);
    },
    resetSendFormbuilderPriorityDialog() {
      this.openFormbuilderPriorityDialog = false;
      this.isFormbuilderMappingEdit = false;
    },
    saveFormPriorityConfigurations() {
      this.upateNode(
        this.selectedNodeIndex,
        "entity_formbuilder_configurations",
        this.entity_formbuilder_configurations
      );
      this.upateNode(
        this.selectedNodeIndex,
        "is_enforce_order",
        this.is_enforce_order
      );
      this.resetSendFormbuilderPriorityDialog();
    },
    getFormbuilderAllFields(formbuilder_id) {
      let selected_form = this.allFormbuilders.find(
        (e) => e._id == formbuilder_id
      );
      if (selected_form?.steps) {
        return selected_form.steps.map((step) => {
          if (step.type == "ENTITY") {
            step.name = this.getFieldProperty(
              this.allEntities,
              step.entity,
              "_id",
              "name",
              []
            );
          }
          return step;
        });
      }
      return [];
    },
    getRelations(row) {
      let allRelationFields = [];
      this.allFieldsWithDataTable.map((field) => {
        if (field.input_type == "ENTITY" && field.data_table_key == undefined) {
          let existedRelation = this.getMultipleEntityRelationships[
            field.entity_id
          ].find(
            (e) =>
              e.parent_entity_id.toString() == row.entity_id.toString() ||
              e.child_entity_id.toString() == row.entity_id.toString()
          );
          if (existedRelation !== undefined) {
            allRelationFields.push(field);
          }
        }
      });
      return allRelationFields;
    },
    openAutomateDrawer(row) {
      this.selectedNodeIndex = row.keyIndex;
      this.currentStep = row;
      if (row?.automation_rules) {
        this.automationRules = row.automation_rules;
        this.rulesType = row.automationRulesType;
      }
      this.configAutomateModel = true;
    },

    async openEmailConfiguration(row) {
      // this.getCompanyUserTypeList();
      this.emailConfigKey = (Math.random() + 1).toString(36).substring(7);
      this.selectedNodeIndex = row.keyIndex;
      if (row?.email_object) {
        this.paramsData = { ...row.email_object };
      } else {
        this.paramsData = {};
      }
      this.configureEmailTemplate = true;
    },
    async openWhatsappConfiguration(row) {
      // this.getCompanyUserTypeList();
      this.emailConfigKey = (Math.random() + 1).toString(36).substring(7);
      this.selectedNodeIndex = row.keyIndex;
      if (row?.whatsapp_object) {
        this.paramsData = { ...row.whatsapp_object };
      } else {
        this.paramsData = {};
      }
      this.configureWhatsappTemplate = true;
    },
    async setEntityFromDetails(value, row) {
      this.selectedNodeIndex = "";
      if (value && value.includes("other/")) {
        row.entity_id = value.replace("other/", "");
        if (!this.allEntityFilters?.[row.entity_id]) {
          await this.$store.dispatch("filters/fetchAllEntityFilters", {
            entityId: row.entity_id,
          });
          if (this.getAllEntityFilters) {
            this.allEntityFilters[row.entity_id] = this.getAllEntityFilters;
          }
        }
        if (row.action == "other/ADD") {
          this.openPrimaryFieldsModal(row);
          row["create_new_data"] = true;
          row["take_data_from"] = "selected_data";
        }
      } else {
        row.entity_id = "";
      }
    },
    checkAnddeleteNode(keyIndex) {
      this.deleteRefresh = true;
      this.loading = true;
      this.form.then = this.deleteNode(this.form.then, keyIndex);
      setTimeout(() => {
        this.deleteRefresh = false;
        this.loading = false;
      }, 500);
    },
    deleteNode(actions, keyIndex) {
      return actions.filter((e) => {
        if (e?.keyIndex == keyIndex) {
          return false;
        } else {
          if (e?.then && e.then.length) {
            e.then = this.deleteNode(e.then, keyIndex);
          }
          if (e?.else && e.else.length) {
            e.else = this.deleteNode(e.else, keyIndex);
          }
        }
        return true;
      });
    },
    upateNode(keyIndex, updateProperty, updateValue) {
      this.form.then = this.mapActions(
        this.form.then,
        keyIndex,
        updateProperty,
        updateValue
      );
    },
    mapActions(actions, keyIndex, updateProperty, updateValue) {
      return actions.map((e) => {
        if (e?.keyIndex == keyIndex) {
          e[updateProperty] = updateValue;
        } else {
          if (e?.then && e.then.length) {
            e.then = this.mapActions(
              e.then,
              keyIndex,
              updateProperty,
              updateValue
            );
          }
          if (e?.else && e.else.length) {
            e.else = this.mapActions(
              e.else,
              keyIndex,
              updateProperty,
              updateValue
            );
          }
        }
        return e;
      });
    },
    async savePipeline(form) {
      if (form?._id) {
        this.loading = true;
        await this.$store.dispatch("automationPipeline/updatePipeline", {
          ...form,
        });
        if (this.getUpdatePipelineStatus) {
          this.loading = false;
          this.$message({
            message: "Updated successfully",
            type: "success",
          });
        } else {
          this.loading = false;
          this.$message({
            message: "Error while update data",
            type: "erroe",
          });
        }
        this.fetchEntityActions();
        this.isEdit = false;
      } else {
        this.loading = true;
        await this.$store.dispatch("automationPipeline/createNewPipeline", {
          ...form,
        });
        if (this.getCreateNewPipeline) {
          this.loading = false;
          this.$message({
            message: "Saved successfully",
            type: "success",
          });
        } else {
          this.loading = false;
          this.$message({
            message: "Error while saving data",
            type: "erroe",
          });
        }
        this.fetchEntityActions();
        this.isEdit = false;
      }
    },
    validateActions(then) {
      if (then && then.length) {
        let empty = then.find((e) => {
          if (!this.validateSingleAction(e)) {
            return true;
          } else if (e?.then && e.then.length) {
            return !this.validateActions(e.then);
          } else if (e?.else && e.else.length) {
            return !this.validateActions(e.else);
          }
        });
        if (empty) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    },
    validateSingleAction(pipeline) {
      if (pipeline?.type) {
        if (pipeline.type == "ACTION") {
          if (pipeline.action) {
            //Other entity
            if (pipeline.action.includes("other/")) {
              let action = pipeline.action.replace("other/", "");
              if (
                action == "UPDATE" &&
                (!pipeline?.entity_from ||
                  !pipeline.update_field ||
                  !pipeline.update_field ||
                  !pipeline.operator ||
                  (pipeline.operator !== "GENERATE_NEW_NUMBER" &&
                    !pipeline.value &&
                    typeof pipeline.value != "boolean" &&
                    typeof pipeline.value != "number" &&
                    !pipeline.value_field))
              ) {
                return false;
              }
              return true;
            }
            // else if (pipeline.action.includes("notify/")) {
            //   let action = pipeline.action.replace("notify/", "");
            // }
            else if (pipeline.action.includes("self/")) {
              let action = pipeline.action.replace("self/", "");
              if (action == "UPDATE" && !pipeline?.update_field) {
                return false;
              }
              if (action == "SEND EMAIL" && !pipeline?.email_object?.body) {
                this.$notify.error({
                  title: "Error",
                  message: "please config Notification",
                });
                return false;
              }             
              return true;
            } else if (pipeline.action.includes("file/")) {
              return true;
            } else if (pipeline.action.includes("formbuilder/")) {
              if (pipeline?.entity_formbuilder_configurations) {
                return true;
              }
            }
          }
          return false;
        } else if (pipeline.type == "CONDITION") {
          return pipeline?.filter_id ? true : false;
        }
      }
      return false;
    },
    validateScheduleSettings(form, type, settings, schedule_data) {
      if (type == "date_field") {
        if (
          (settings.executed_on_date != "On" &&
            !settings.executed_on_date_value) ||
          (settings.recurring_execution_type != "Once" &&
            !settings.recurring_execution_value) ||
          (settings.recurring_stopping_condition == "After" &&
            !settings.recurring_stopping_value)
        ) {
          return {
            status: false,
            message: "Please Config required settings",
          };
        }
        console.log("schedule data", schedule_data);
        if (!schedule_data?.key) {
          return {
            status: false,
            message: "Please Select Date/Time Field",
          };
        }
      } else if (type == "Select_manually") {
        if (
          !settings.minutes.length ||
          !settings.hours.length ||
          !settings.days.length ||
          !settings.months.length ||
          !settings.days_of_week.length ||
          !settings.years.length
        ) {
          return {
            status: false,
            message: "Please check at least box in every field",
          };
        }
        const selectedDateTime = new Date(
          Math.min(...settings.years),
          this.getMonthIndex(settings.months[0]),
          Math.min(...settings.days),
          Math.min(...settings.hours),
          Math.min(...settings.minutes)
        );
        const currentDateTime = new Date();
        if (selectedDateTime < currentDateTime) {
          return {
            status: false,
            message: `Selected date and time are in the past. Please select a future date and time.`,
          };
        }
      }
      return {
        status: true,
      };
    },
    getMappedField(fields, keyvalue, keyProperty) {
      let data = JSON.parse(
        JSON.stringify(
          this.getFieldProperty(fields, keyvalue, keyProperty, "", {})
        )
      );
      if (data) {
        data["key"] = "value";
        if (data?.properties) {
          data.properties = {
            ...data.properties,
            ...{
              ["hideLabel"]: true,
            },
          };
        } else {
          data["properties"] = {
            ["hideLabel"]: true,
          };
        }
        data["allow_quick_add"] = false;
      }
      return data;
    },
    getFieldProperty(
      fields,
      key,
      keyProperty,
      property = "",
      defaultValue = ""
    ) {
      if (fields && fields.length && key && keyProperty) {
        let field = fields.find((e) => e && e[keyProperty] == key);
        if (field) {
          if (property) {
            return field[property] ? field[property] : defaultValue;
          }
          return field;
        }
        return defaultValue;
      }
      return defaultValue;
    },
    getFieldsFromTemplate(data, include_data_tables, include_data_table_key) {
      let fields = [];
      let tempData = JSON.parse(JSON.stringify(data));
      let contentFields = [
        "HEADING",
        "PARAGRAPH",
        "HTML_CONTENT",
        "SINGLE_LINE_CONTENT",
        "VIDEO",
        "ACTION_BUTTON",
        "HORIZONTAL_LINE",
        "DIVISION",
      ];
      tempData.sections.forEach((e) => {
        fields = [
          ...fields,
          ...e.fields.filter((e) => contentFields.indexOf(e.inputType) === -1),
        ];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        e.template_name = data.name;
        if (!e.template_key) {
          e.template_key = e.template_id + "#" + e.key;
        }
        if (!e.mention_key) {
          e.mention_key = e.template_id + "#" + e.key;
        }
        if (e.key && !e.key.includes("#")) {
          e.key = e.template_id + "#" + e.key;
        }
        return e;
      });
      if (include_data_tables) {
        //have to check data table fields
        let dataTableFields = fields.filter(
          (e) => e?.inputType == "DATA_TABLE"
        );
        if (dataTableFields && dataTableFields.length) {
          dataTableFields.forEach((fl) => {
            if (fl?.data_table_columns) {
              fields = [
                ...fields,
                ...fl?.data_table_columns.map((e) => {
                  e.data_table_key = fl.key;
                  e.template_id = data._id;
                  e.template_name = data.name;
                  e.label = fl.label + "-" + e.label;
                  if (include_data_table_key) {
                    e.key = e.data_table_key + ":" + e.key;
                  }
                  e.isDataTableKey = true;
                  return e;
                }),
              ];
            }
          });
        }
        fields = fields.filter((e) => {
          return e.inputType != "DATA_TABLE";
        });
      }
      // console.log("include_pay_Variables",include_pay_Variables)
      // if (include_pay_Variables) {
      const paymentFields = [
        {
          label: "Transaction Id",
          key: "txn_id",
          input_type: "SINGLE_LINE_TEXT",
        },
        { label: "Gateway", key: "gateway", input_type: "SINGLE_LINE_TEXT" },
        { label: "Name", key: "name", input_type: "SINGLE_LINE_TEXT" },
        { label: "Email", key: "email", input_type: "SINGLE_LINE_TEXT" },
        {
          label: "Payment Type",
          key: "paymentType",
          input_type: "SINGLE_LINE_TEXT",
        },
        { label: "Method", key: "method", input_type: "SINGLE_LINE_TEXT" },
        { label: "Currency", key: "currency", input_type: "SINGLE_LINE_TEXT" },
        { label: "Status", key: "status", input_type: "SINGLE_LINE_TEXT" },
        {
          label: "Payment Session",
          key: "paymentSession",
          input_type: "SINGLE_LINE_TEXT",
        },
        {
          label: "Description",
          key: "description",
          input_type: "MULTI_LINE_TEXT",
        },
        {
          label: "Phone Number",
          key: "phoneNumber",
          input_type: "PHONE_COUNTRY_CODE",
        },
        { label: "Payment Date", key: "paymentDate", input_type: "DATE" },
        { label: "Refund Date", key: "refundDate", input_type: "DATE" },
        {
          label: "Payment Date &Time",
          key: "paymentDate",
          input_type: "DATE_TIME",
        },
        {
          label: "Refund Date &Time",
          key: "refundDate",
          input_type: "DATE_TIME",
        },
        { label: "Total Amount", key: "amount", input_type: "CURRENCY" },
        { label: "Paid Amount", key: "paidAmount", input_type: "CURRENCY" },
        {
          label: "Total Paid Amount",
          key: "totalPaidAmount",
          input_type: "CURRENCY",
        },
        {
          label: "Amount Refunded",
          key: "amountRefunded",
          input_type: "CURRENCY",
        },
      ];
      let payButtonsFields = fields.filter((e) => e?.inputType == "PAY_BUTTON");
      if (payButtonsFields && payButtonsFields.length) {
        payButtonsFields.map((e) => {
          let payFields = paymentFields.map((fl) => {
            return {
              ...payVariables,
              key: e.key + "_" + fl.key,
              parent_key: e.key,
              template_id: e.template_id,
              _id: "",
              global_variable_pay_field: fl.key,
              label: e.label + " - " + fl.label,
              inputType: fl.input_type,
            };
          });
          fields = [...fields, ...payFields];
          return payFields;
        });
      }
      // console.log("After pay fields",fields)
      return this.hideFieldsLabels(fields);
    },
    setValueField(key, row, fields) {
      let field = fields.find(
        (e) =>
          e.key == key ||
          (key &&
            key.includes("/") &&
            key.split("/")[1] == e.key &&
            e.data_table_key == key.split("/")[0])
      );
      if (field?.data_table_key) {
        row.value_field_data_table_key = field?.data_table_key;
      }
    },
    setUpdateField(key, row, fields) {
      if (key) {
        let field = fields.find((e) => e.key == key);
        this.stepRefreshIndex = "";
        row.input_type = field.inputType;
        // row["operator"] = "=";
        this.stepRefreshIndex = row.keyIndex;
        setTimeout(() => {
          this.stepRefreshIndex = "";
        }, 100);
      } else {
        row.input_type = "";
        row["operator"] = "";
      }
    },
    getFieldInputType(step) {
      return this.getFieldProperty(
        step.input_type == "DATA_TABLE"
          ? this.getFieldProperty(
              this.actionEntityFields[step.entity_id],
              step.update_field,
              "key",
              "data_table_columns"
            )
          : this.actionEntityFields[step.entity_id],
        step.input_type == "DATA_TABLE"
          ? step.data_table_column_update_field
          : step.update_field,
        "key",
        "inputType"
      );
    },
    setFieldDetails() {
      this.stepRefresh = true;
      setTimeout(() => {
        this.stepRefresh = false;
      }, 100);
    },
    async getEntityDetails(key, row) {
      let fieldKey =
        key.split("#").length == 3
          ? key.split("#")[1] + "#" + key.split("#")[2]
          : key;
      let field = this.allFieldsWithDataTable.find((e) => e.key == fieldKey);
      if (field?.entity_id) {
        row.entity_id = field?.entity_id;
        row.entity_type = "FIELD";
        if (field?.data_table_key) {
          row.data_table_key = field.data_table_key;
        }
        if (!this.actionEntityFields?.[field.entity_id]) {
          this.loading = true;
          await this.$store.dispatch("entities/fetchEntityById", {
            entity_id: field.entity_id,
          });
          if (this.getEntityDataById) {
            this.actionEntityFields[field.entity_id] =
              this.getAllFieldsFromEntity(this.getEntityDataById, false);
          }
          this.loading = false;
        }
      } else {
        row.entity_type = "RELATIONSHIP";
      }
    },
    getTemplateFields1(
      temp,
      include_data_tables = false,
      include_data_table_key = false,
      include_label = false
    ) {
      let fields = [];
      let data = JSON.parse(JSON.stringify(temp));
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        if (e.key && !e.key.includes("#")) {
          e.key = data._id + "#" + e.key;
        }
        e.template_name = data.name;
        if (include_label) {
          e.label = data.name + " - " + e.label;
        }
        e.data_type =
          e.validations && e.validations.type ? e.validations.type : "STRING";
        return e;
      });
      if (include_data_tables) {
        //have to check data table fields
        let dataTableFields = fields.filter(
          (e) => e?.inputType == "DATA_TABLE"
        );
        if (dataTableFields && dataTableFields.length) {
          dataTableFields.forEach((fl) => {
            if (fl?.data_table_columns) {
              fields = [
                ...fields,
                ...fl?.data_table_columns.map((e) => {
                  e.data_table_key = fl.key;
                  e.template_id = data._id;
                  e.label = fl.label + "-" + e.label;
                  e.template_name = data.name;
                  if (include_data_table_key) {
                    e.key = e.data_table_key + "." + e.key;
                  }
                  return e;
                }),
              ];
            }
          });
        }
        fields = fields.filter((e) => {
          return e.inputType != "DATA_TABLE";
        });
      }
      return fields;
    },
    getAllFieldsFromEntity(
      entity,
      include_data_tables,
      include_data_table_key = false,
      include_customization = false,
      include_label = false
    ) {
      let allFields = [];
      let data = JSON.parse(JSON.stringify(entity));
      (data?.templates || []).forEach((template) => {
        if (template?.templateInfo?.sections) {
          let tempFields = [];
          if (include_customization) {
            tempFields = this.applyCustomizationOnFields(
              this.getTemplateFields1(
                template.templateInfo,
                include_data_tables,
                include_data_table_key,
                include_label
              ),
              template.customization,
              template,
              include_label
            ).map((f) => {
              if (f?.key && !f.key.includes("#")) {
                f.key = f.template_id + "#" + f.key;
              }
              return f;
            });
          } else {
            tempFields = this.getFieldsFromTemplate(
              template?.templateInfo,
              include_data_tables,
              include_data_table_key
            );
          }
          allFields = [...allFields, ...tempFields];
        }
      });
      return allFields;
    },
    fetchTemplateFieldsByType(types, allFields, entityId, key = "") {
      if (types && allFields) {
        let result = this.getFieldsByType(types, allFields);
        if (types.indexOf("ENTITY") != -1 && entityId) {
          return result.filter((e) => e.entity_id == entityId);
        } else if (
          key.includes(":") &&
          this.dataTableConfigObject[key.split(":")[0]]?.selected_type ==
            "table_fields" &&
          this.dataTableConfigObject[key.split(":")[0]]?.selected_data_table_key
        ) {
          let res = result.filter(
            (e) =>
              e.data_table_key ==
              this.dataTableConfigObject[key.split(":")[0]]
                .selected_data_table_key
          );
          return res;
        }
        result = result.filter((e) => !e.isDataTableKey);
        return result;
      }
      return allFields;
    },
    getFieldsByType(types, allFields) {
      // console.log("Get Fields by Type",types, allFields)
      if (types && types.length) {
        if (types.find((e) => e == "CURRENCY" || e == "NUMBER")) {
          types.push("CURRENCY");
          types.push("NUMBER");
        }
        return (allFields || []).filter(
          (e) =>
            types.indexOf(e.inputType) != -1 ||
            (e.inputType == "FORMULA" && types.indexOf(e.result_type) != -1) ||
            (e.inputType == "AGGREGATE_FUNCTION" &&
              (types.indexOf("NUMBER") != -1 ||
                types.indexOf("CURRENCY") != -1))
        );
      }
      return allFields || [];
    },
    getFieldsForSelfAutomation(allFields) {
      let types = [
        "ENTITY",
        "ENTITY_VARIABLE",
        "PAYMENT_VARIABLE",
        "PAY_BUTTON",
        "FORMULA",
        "AGGREGATE_FUNCTION",
        "AUTO_INCREMENT_NUMBER",
      ];
      if (types && types.length) {
        return (allFields || []).filter(
          (e) => types.indexOf(e.input_type) < 0
          // ||
          // (e.inputType == "FORMULA" && types.indexOf(e.result_type) != -1) ||
          // (e.inputType == "AGGREGATE_FUNCTION" &&
          //   (types.indexOf("NUMBER") != -1 ||
          //     types.indexOf("CURRENCY") != -1))
        );
      }
      return allFields || [];
    },
    getUserEntities(fields) {
      return fields.filter((field) => {
        if (field.entity_id) {
          let entity = this.allEntities.find((e) => e._id == field.entity_id);
          if (["INDIVIDUAL", "BUSINESS"].includes(entity?.entity_type)) {
            return true;
          }
        }
      });
    },
    getFormbuildersRelated(entityKey, allFields) {
      if (entityKey && allFields) {
        let fieldKey = entityKey.split("#")[1] + "#" + entityKey.split("#")[2];
        let field = allFields.find((e) => e.key == fieldKey);
        if (field?.entity_id) {
          return this.allFormbuilders.filter((form) => {
            if (
              (form.form_builders_owner == "ENTITY" &&
                form.entity_id == field.entity_id) ||
              ["COMPANY"].includes(form.form_builders_owner)
            ) {
              return true;
            }
          });
        }
        return [];
      }
      return [];
    },
    getMonthIndex(month) {
      return [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ].indexOf(month);
    },
    checkAutofillEntityData(e) {
      if (e.inputType == "ENTITY_TABLE" && e.data_table_field_auto_fill) {
        return true;
      }
      return false;
    },
    addRefreshEntity() {
      this.entityRefreshList.push({});
    },
    openRefreshEntityCofigureModal(row) {
      (this.allEntities || []).forEach((entity) => {
        this.actionEntityFields[entity._id] = this.getAllFieldsFromEntity(
          entity,
          false
        );
      });
      this.selectedNodeIndex = row.keyIndex;
      this.currentStep = row;
      this.entityRefreshList = row.entity_refresh_list || [];
      this.entityRefreshModal = true;
    },
    resetRefreshEntityCofigureTemplate() {
      this.upateNode(
        this.selectedNodeIndex,
        "entity_refresh_list",
        this.entityRefreshList
      );
      this.selectedNodeIndex = "";
      this.currentStep = null;
      this.entityRefreshList = [];
      this.entityRefreshModal = false;
    },
  },
};
