<template>
  <div
    v-loading="loading"
    :element-loading-text="loadingText"
    :style="getStyle"
  >
    <template v-if="!hideTopbar">
      <div class="outer-div">
        <div class="outer-div">
          <el-button
            v-if="!fromCustomDashboard && !this.$route.query.routeType"
            icon="el-icon-back"
            size="mini"
            @click="backToEntitiesViews"
          ></el-button>
          <div v-if="!fromCustomDashboard" class="head-title text-dark ml-1">
            {{ payload.name }}
          </div>
          <b v-else class="mt-3 ml-2">{{ component.component_name }}</b>
        </div>
        <div>
          <el-dropdown
            placement="bottom"
            v-if="
              fromCustomDashboard &&
              showExport &&
              getFilterFields &&
              !getFilterFields.length
            "
            class="mr-2"
          >
            <Button
              type="button"
              class="bt btn btn-sm btn-outline-secondary m-lr-1"
              ><i class="fa fa-upload"></i
            ></Button>
            <el-dropdown-menu>
              <a @click="exportToExcel">
                <el-dropdown-item>
                  <i class="fa fa-file-excel-o"></i>
                  Excel
                </el-dropdown-item>
              </a>
              <a @click="exportToPDF(false)" v-if="pdfExport">
                <el-dropdown-item>
                  <i class="fa fa-file-pdf-o"></i>
                  PDF
                </el-dropdown-item>
              </a>
              <a
                @click="openSendNotification"
                v-if="pdfExport && checkAttachmentField"
              >
                <el-dropdown-item>
                  <i class="fa fa-file-pdf-o"></i>
                  {{ fromCustomDashboard ? "Send" : "Send PDF as attachment" }}
                </el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </el-dropdown>
          <el-input
            v-if="fromCustomDashboard"
            size="mini"
            prefix-icon="el-icon-search"
            style="width: 200px; margin-right: 10px"
            placeholder="Search"
            v-model="search_string"
            clearable
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search"
              size="mini"
            ></el-button>
          </el-input>
          <el-button
            v-if="getFilterFields && getFilterFields.length"
            :type="fromCustomDashboard ? 'text' : 'primary'"
            :icon="!isFilterOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
            size="mini"
            @click="openFiltersCollapse"
            style="margin-right: 8px"
            >{{
              !fromCustomDashboard
                ? !isFilterOpen
                  ? payload.filter_open
                  : payload.filter_close
                : ""
            }}</el-button
          >
          <el-dropdown
            trigger="click"
            v-if="fromCustomDashboard && !hide_options"
            style="margin-right: 8px"
          >
            <el-link
              icon="el-icon-more"
              :underline="false"
              style="transform: rotate(90deg); height: 40px"
            ></el-link>

            <el-dropdown-menu slot="dropdown">
              <a @click="editComponent">
                <el-dropdown-item>Edit</el-dropdown-item>
              </a>
              <a @click="deleteComponent">
                <el-dropdown-item>Delete</el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            class="fullscreen-button"
            icon="el-icon-full-screen"
            type="text"
            style="margin-right: 8px"
            v-if="fromCustomDashboard"
            @click="expandEntityView"
          >
          </el-button>
        </div>
      </div>
      <div v-if="isFilterOpen && getFilterFields && getFilterFields.length">
        <div class="filters-cards">
          <div
            v-for="(filter, i) in getFilterFields"
            :key="filter.key + '_' + i"
          >
            <label class="text-dark" v-if="!fromCustomDashboard">
              {{ filter.label }}:
              <span class="error" v-if="filter.required">*</span>
              <i class="el-icon-star-on" v-if="filter.primary_filter"></i>
            </label>
            <div v-if="['ENTITY', 'RELATION'].includes(filter.type)">
              <el-tooltip :content="filter.label" placement="top-start">
                <el-select
                  v-model="selectedFileds[filter.key]"
                  filterable
                  :multiple="filter.multiple ? true : false"
                  collapse-tags
                  :placeholder="'Select ' + filter.label"
                  :style="'width: 100%;'"
                  :loading="checkLoading(filter.key)"
                  :disabled="
                    isApplicationUserSide &&
                    filter.isApplicationUser &&
                    getActiveContactType.contact_type._id == filter.entity_id
                  "
                  @change="changeFilterData(filter)"
                >
                  <el-option value="all" label="All" v-if="!filter.required">
                  </el-option>
                  <template v-if="!refresh">
                    <el-option
                      v-for="(temp, i) in allEntityDataList(filter.entity_id)"
                      :key="temp._id + '_' + i"
                      :value="temp._id"
                      :label="getLabel(temp, i, filter.entity_id)"
                    >
                    </el-option>
                  </template>
                </el-select>
              </el-tooltip>
            </div>
            <div
              v-else-if="
                filter.type == 'DATE' && filter.filter_type == 'PICKER_OPTIONS'
              "
            >
              <el-tooltip :content="filter.label" placement="top-start">
                <el-select
                  :placeholder="'Pick date range'"
                  v-model="selectedFileds[filter.key]"
                  @change="
                    (changedValue) => setDatePickerDate(changedValue, false)
                  "
                  :style="'width: 100%;'"
                >
                  <el-option
                    v-for="(el, index) in pickerOptions"
                    :key="el.value + '_' + index"
                    :value="el.value"
                    :label="el.label"
                    >{{ el.label }}
                  </el-option>
                </el-select>
              </el-tooltip>
            </div>
            <div
              v-else-if="
                filter.type == 'DATE' && filter.filter_type == 'DATE_RANGE'
              "
            >
              <el-tooltip :content="filter.label" placement="top-start">
                <el-date-picker
                  v-model="selectedFileds[filter.key]"
                  :type="checkIsDateSelected(filter) ? 'month' : 'daterange'"
                  range-separator="To"
                  start-placeholder="Start date"
                  end-placeholder="End date"
                  :format="
                    getDefaultDateFormat &&
                    getDefaultDateFormat.includes('Do') == false
                      ? getDefaultDateFormat
                          .replace('YYYY', 'yyyy')
                          .replace('DD', 'dd')
                      : 'MM-dd-yyyy'
                  "
                >
                </el-date-picker>
              </el-tooltip>
            </div>
            <div
              v-else-if="
                filter.type == 'SELECT' || filter.type == 'MULTI_SELECT'
              "
            >
              <el-tooltip :content="filter.label" placement="top-start">
                <el-select
                  v-model="selectedFileds[filter.key]"
                  filterable
                  :multiple="filter.multiple ? true : false"
                  collapse-tags
                  :placeholder="'Select ' + filter.label"
                  :style="'width: 100%;'"
                  @change="changeFilterData(filter)"
                >
                  <el-option value="all" label="All" v-if="!filter.required">
                  </el-option>
                  <el-option
                    v-for="(temp, i) in getFieldOptions(filter)"
                    :key="temp + '_' + i"
                    :value="temp"
                    :label="temp"
                  >
                  </el-option>
                </el-select>
              </el-tooltip>
            </div>
          </div>
          <div
            :style="`margin-top: ${
              payload.filter_fields.length > 4 ? 20 : 5
            }px;display:flex;height:32px;float:${
              fromCustomDashboard ? 'right' : 'none'
            }`"
          >
            <el-tooltip content="Run" placment="top-start">
              <Button
                class="bt btn btn-outline-primary btn-sm m-lr-1"
                type="button"
                style="width: 70px"
                @click="applyFilters"
                ><i class="fa fa-cogs" aria-hidden="true"></i>
                {{ fromCustomDashboard ? "" : "Run" }}</Button
              >
            </el-tooltip>
            <el-tooltip content="Reset" placment="top-start">
              <Button
                type="button"
                style="width: 80px"
                class="bt btn btn-outline-secondary btn-sm m-lr-1"
                @click="resetFilters"
                ><i class="fa fa-refresh" aria-hidden="true"></i>
                {{ fromCustomDashboard ? "" : "Reset" }}</Button
              >
            </el-tooltip>
            <el-dropdown placement="bottom" v-if="showExport">
              <Button
                type="button"
                class="bt btn btn-sm btn-outline-secondary m-lr-1"
                style="height: 32px; width: 80px"
                ><i class="fa fa-upload"></i
                >{{ fromCustomDashboard ? "" : "Export" }}</Button
              >
              <el-dropdown-menu>
                <a @click="exportToExcel">
                  <el-dropdown-item>
                    <i class="fa fa-file-excel-o"></i>
                    {{ fromCustomDashboard ? "Excel" : "Export as Excel" }}
                  </el-dropdown-item>
                </a>
                <a @click="exportToPDF(false)" v-if="pdfExport">
                  <el-dropdown-item>
                    <i class="fa fa-file-pdf-o"></i>
                    {{ fromCustomDashboard ? "PDF" : "Export as PDF" }}
                  </el-dropdown-item>
                </a>
                <a
                  @click="openSendNotification"
                  v-if="pdfExport && checkAttachmentField"
                >
                  <el-dropdown-item>
                    <i class="fa fa-file-pdf-o"></i>
                    {{
                      fromCustomDashboard ? "Send" : "Send PDF as attachment"
                    }}
                  </el-dropdown-item>
                </a>
              </el-dropdown-menu>
            </el-dropdown>
            <div>
              <button
                class="btn btn-outline-secondary btn-sm m-lr-1"
                type="button"
                style="height: 30px"
                @click="openSettingsDialog"
              >
                <i class="fa fa-cog fa-spin"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <el-row v-if="!fromCustomDashboard">
        <el-col class="mb-1 mt-1">
          <el-input
            size="mini"
            prefix-icon="el-icon-search"
            style="width: 250px; float: right"
            placeholder="Search"
            v-model="search_string"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search"
              size="mini"
            ></el-button>
          </el-input>
          <el-dropdown
            placement="bottom"
            v-if="showExport && getFilterFields && !getFilterFields.length"
            style="float: right"
            class="mr-2"
          >
            <Button
              type="button"
              class="bt btn btn-sm btn-outline-secondary m-lr-1"
              style="width: 80px"
              ><i class="fa fa-upload"></i>Export</Button
            >
            <el-dropdown-menu>
              <a @click="exportToExcel">
                <el-dropdown-item>
                  <i class="fa fa-file-excel-o"></i>
                  Export as Excel
                </el-dropdown-item>
              </a>
              <a @click="exportToPDF(false)" v-if="pdfExport">
                <el-dropdown-item>
                  <i class="fa fa-file-pdf-o"></i>
                  Export as PDF
                </el-dropdown-item>
              </a>
              <a
                @click="openSendNotification"
                v-if="pdfExport && checkAttachmentField"
              >
                <el-dropdown-item>
                  <i class="fa fa-file-pdf-o"></i>
                  {{ fromCustomDashboard ? "Send" : "Send PDF as attachment" }}
                </el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
    </template>
    <div>
      <!-- <el-scrollbar
        wrap-style="max-height: 80vh; max-width: fit-content;scrollbar-width:thin;"
      > -->
      <div class="main-table-holder mt-1 pr-1 p-10">
        <div
          class="vue-data-table-default"
          v-for="(row, index) in Object.keys(data)"
          :key="index"
        >
          <h4 v-if="row != 'NO_PRIMARY_FILTER'">
            {{ getPrimaryFilterLabel(row) }}
          </h4>
          <el-table
            :data="getData(data[row])"
            :resizable="true"
            style="width: 100%"
            border
            :show-summary="
              removeHiddenColumns(payload.main_columns || []).find(
                (e) => e.include_total
              )
                ? true
                : false
            "
            :summary-method="getSummaries"
            :default-sort="getDefaultSort"
            :height="500"
            :max-height="fromCustomDashboard ? (isFilterOpen ? 250 : 300) : 500"
            v-el-table-infinite-scroll="() => loadMore(row)"
            row-key="parentDataId"
          >
            <el-table-column
              v-if="payload.add_serial_number"
              label="S.No."
              id="sno"
              prop="sno"
              width="75"
            >
              <template slot-scope="scope">{{ scope.$index + 1 }}</template>
            </el-table-column>
            <el-table-column
              v-for="(step, i) in getSelectedColumns()"
              :key="step.key + '_' + i"
              :label="step.label"
              :id="step.key"
              :prop="getProp(step)"
              :width="step.label.length > 12 ? step.label.length * 12 : 'auto'"
              :min-width="step.label.length > 12 ? step.label.length * 12 : 150"
              :align="step.alignment ? step.alignment : 'left'"
              sortable
            >
              <template slot-scope="scope">
                <div :style="getColumnStyle(step, scope.row)">
                  <span
                    v-if="
                      scope.row[getProp(step)] == 'MULTIPLE' &&
                      step.allow_view_details &&
                      step.detailed_entity_view_id
                    "
                  >
                    <el-link
                      type="primary"
                      :underline="false"
                      @click="openDetailedView(scope.$index, step, row)"
                    >
                      Multiple
                    </el-link>
                  </span>
                  <span
                    v-else-if="
                      getField(step) &&
                      getField(step).inputType == 'MULTI_LINE_TEXT' &&
                      scope.row[getProp(step)] !== step.default_value
                    "
                  >
                    {{ cleanText(scope.row[getProp(step)]) }}
                  </span>
                  <span
                    v-else-if="
                      getField(step) &&
                      getField(step).inputType == 'DATE' &&
                      scope.row[getProp(step)] !== step.default_value
                    "
                    @click="openChildData(scope.row, step)"
                    :style="
                      checkChildView(step)
                        ? 'color: #409EFF !important; cursor: pointer; font-weight: 500;'
                        : ''
                    "
                  >
                    {{ scope.row[getProp(step)] | globalDateFormat }}
                  </span>
                  <span
                    v-else-if="['created_at', 'updated_at'].includes(step.key)"
                    @click="openChildData(scope.row, step)"
                    :style="
                      checkChildView(step)
                        ? 'color: #409EFF !important; cursor: pointer; font-weight: 500;'
                        : ''
                    "
                  >
                    {{ scope.row[getProp(step)] | globalDateTimeFormat }}
                  </span>
                  <span
                    v-else-if="['created_by', 'updated_by'].includes(step.key)"
                    @click="openChildData(scope.row, step)"
                    :style="
                      checkChildView(step)
                        ? 'color: #409EFF !important; cursor: pointer; font-weight: 500;'
                        : ''
                    "
                  >
                    {{
                      scope.row[step.key][0].first_name +
                      " " +
                      scope.row[step.key][0].last_name
                    }}
                  </span>
                  <span
                    v-else-if="
                      getField(step) &&
                      getField(step).inputType == 'DATE_TIME' &&
                      scope.row[getProp(step)] !== step.default_value
                    "
                    @click="openChildData(scope.row, step)"
                    :style="
                      checkChildView(step)
                        ? 'color: #409EFF !important; cursor: pointer; font-weight: 500;'
                        : ''
                    "
                  >
                    {{ scope.row[getProp(step)] | globalDateTimeFormat }}
                  </span>
                  <span
                    v-else-if="
                      getField(step) &&
                      getField(step).inputType == 'FILE' &&
                      scope.row[getProp(step)]
                    "
                  >
                    <el-button
                      type="text"
                      style="margin-top: -14px"
                      @click="downloadFile(scope.row[getProp(step)])"
                      class="mb-1"
                    >
                      <p style="font-size: 13px">
                        Download <i class="el-icon-download"></i>
                      </p>
                    </el-button>
                  </span>
                  <span
                    v-else-if="
                      getField(step) &&
                      getField(step).input_type == 'FORMULA' &&
                      isDate(scope.row[getProp(step)])
                    "
                    @click="openChildData(scope.row, step)"
                    :style="
                      checkChildView(step)
                        ? 'color: #409EFF !important; cursor: pointer; font-weight: 500;'
                        : ''
                    "
                  >
                    <p>
                      {{ scope.row[getProp(step)] | globalDateFormat }}
                    </p>
                  </span>
                  <span
                    v-else-if="
                      getField(step) &&
                      (getField(step).inputType == 'AGGREGATE_FUNCTION' ||
                        getField(step).inputType == 'CURRENCY' ||
                        getField(step).inputType == 'FORMULA')
                    "
                    @click="openChildData(scope.row, step)"
                    :style="
                      checkChildView(step)
                        ? 'color: #409EFF !important; cursor: pointer; font-weight: 500;'
                        : ''
                    "
                  >
                    {{
                      getCurrencyFormat(
                        scope.row[getProp(step)],
                        getField(step)
                      )
                    }}
                  </span>
                  <span
                    v-else
                    @click="openChildData(scope.row, step)"
                    :style="
                      checkChildView(step)
                        ? 'color: #409EFF !important; cursor: pointer; font-weight: 500;'
                        : ''
                    "
                  >
                    {{ scope.row[getProp(step)] }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <template v-if="checkIsDividedFilterAdded">
              <el-table-column
                v-for="day in getCurrentMonthDays"
                :key="day.dayName + '_date_' + day.date"
                :prop="day.month + '-' + day.date + '-' + day.dayName"
              >
                <template slot="header" slot-scope="scope">
                  <div class="text-center">
                    <span>{{ scope.column.property.split("-")[2] }}</span>
                    <div>
                      {{
                        `${scope.column.property.split("-")[1]}-${
                          scope.column.property.split("-")[0]
                        }`
                      }}
                    </div>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div
                    :style="
                      getColumnStyle(
                        { key: day.month + '-' + day.date + '-' + day.dayName },
                        scope.row
                      )
                    "
                  >
                    <div
                      style="cursor: pointer !important"
                      @click="openSlots(day, scope.row)"
                    >
                      {{
                        scope.row[
                          day.month + "-" + day.date + "-" + day.dayName
                        ]
                          ? scope.row[
                              day.month + "-" + day.date + "-" + day.dayName
                            ]
                          : 0
                      }}
                    </div>
                  </div>
                </template>
              </el-table-column>
            </template>
          </el-table>
          <div
            style="text-align: center; gap: 10px"
            v-if="
              paginationData &&
              paginationData[row] &&
              paginationData[row].tableDataPages > 1 &&
              paginationData[row].infiniteDisabled
            "
          >
            <i class="el-icon-loading"></i><span>Loading more...</span>
          </div>
          <div
            class="d-flex mt-2"
            v-if="
              payload && payload.aggregations && payload.aggregations.length
            "
            style="float: right"
          >
            <div
              v-for="(aggregation, index) in payload.aggregations"
              :key="index"
              class="ml-4"
            >
              <b>{{ aggregation.label }}</b> :
              <span>{{ aggregationValues[row][aggregation.label] }}</span>
            </div>
          </div>
          <el-divider></el-divider>
        </div>
      </div>
      <!-- </el-scrollbar> -->
    </div>
    <dialog-component
      :visible="detailedViewModal"
      :title="detailedEntityViewTitle"
      :containerWidth="'100%'"
      :containerHeight="'100%'"
      @before-close="detailedViewModal = false"
    >
      <div>
        <EntityDetailedView
          v-if="detailedEntityViewId && detailedViewModal"
          :hideTopbar="true"
          :filtersData="detailedViewFilters"
          :entityViewId="detailedEntityViewId"
        >
        </EntityDetailedView>
      </div>
      <span slot="footer" class="dialog-footer">
        <button
          @click="closeDetailedView"
          class="btn btn-outline-danger btn-sm m-lr-1"
          type="button"
        >
          <i class="fa fa-times"></i>Cancel
        </button>
      </span>
    </dialog-component>
    <el-dialog
      :width="getIsMobile ? '100%' : '30%'"
      :visible.sync="openDialogVisible"
      title="Edit Component"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div v-loading="loading">
        <CustomComponentEdit
          :addStatsData="updateComponentData"
          :getRelationshipEntities="[]"
          :selectedEntity="{}"
          :globalVariablesData="globalVariablesData"
        ></CustomComponentEdit>
      </div>
      <span slot="footer" class="dialog-footer">
        <button
          class="btn btn btn-outline-danger btn-sm m-lr-1"
          type="button"
          @click="openDialogVisible = false"
        >
          <i class="fa fa-close"></i> Cancel
        </button>
        <button
          class="btn btn btn-outline-primary btn-sm m-lr-1"
          type="button"
          @click="updateComponent"
        >
          <i class="fa fa-sign-out"></i> Save
        </button>
      </span>
    </el-dialog>
    <vue-html2pdf
      v-show="false"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      filename="hee hee"
      :pagebreak="{
        mode: 'css',
      }"
      :pdf-quality="10"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="potrait"
      :html-to-pdf-options="{
        html2canvas: { useCORS: true, scale: 4 },
      }"
      ref="html2Pdf"
    >
      <section slot="pdf-content" id="form" style="margin: 20px">
        <ViewTemplate
          v-if="payload && payload.printing_template && !loading"
          :templateIdViewId="payload.printing_template"
          :fromEntityViews="true"
          :formData="getFiltersData"
        >
        </ViewTemplate>
        <hr />
        <div
          class="vue-data-table-default"
          v-for="(row, index) in Object.keys(data)"
          :key="index"
        >
          <span>
            <b v-if="row != 'NO_PRIMARY_FILTER'">
              {{ getPrimaryFilterLabel(row) }}
            </b>
          </span>
          <table
            :data="getData(data[row])"
            style="width: 100%"
            class="custom-table"
            border
          >
            <thead>
              <tr>
                <th
                  v-for="(step, i) in removeHiddenColumns(
                    payload.main_columns || []
                  )"
                  :key="step.key + '_' + i"
                  :width="step.label.length > 12 ? step.label.length * 12 : 150"
                  :align="step.alignment ? step.alignment : 'left'"
                >
                  {{ step.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(rowData, rowIndex) in getData(data[row])"
                :key="rowIndex"
              >
                <td
                  v-for="(step, i) in removeHiddenColumns(
                    payload.main_columns || []
                  )"
                  :key="step.key + '_' + i"
                >
                  <span
                    v-if="
                      rowData[getProp(step)] == 'MULTIPLE' &&
                      step.allow_view_details &&
                      step.detailed_entity_view_id
                    "
                  >
                    <a
                      href="#"
                      @click.prevent="openDetailedView(rowIndex, step, row)"
                    >
                      Multiple
                    </a>
                  </span>
                  <span
                    v-else-if="
                      getField(step) &&
                      getField(step).inputType == 'DATE' &&
                      rowData[getProp(step)] !== step.default_value
                    "
                  >
                    {{ rowData[getProp(step)] | globalDateFormat }}
                  </span>
                  <span
                    v-else-if="
                      getField(step) &&
                      getField(step).inputType == 'DATE_TIME' &&
                      rowData[getProp(step)] !== step.default_value
                    "
                  >
                    {{ rowData[getProp(step)] | globalDateTimeFormat }}
                  </span>
                  <span
                    v-else-if="
                      getField(step) &&
                      getField(step).inputType == 'FILE' &&
                      rowData[getProp(step)]
                    "
                  >
                    <button
                      type="button"
                      style="margin-top: -14px"
                      @click="downloadFile(rowData[getProp(step)])"
                      class="mb-1"
                    >
                      <p style="font-size: 13px">
                        Download <i class="el-icon-download"></i>
                      </p>
                    </button>
                  </span>
                  <span
                    v-else-if="
                      getField(step) &&
                      getField(step).input_type == 'FORMULA' &&
                      isDate(rowData[getProp(step)])
                    "
                  >
                    <p>
                      {{ rowData[getProp(step)] | globalDateFormat }}
                    </p>
                  </span>
                  <span
                    v-else-if="
                      getField(step) &&
                      (getField(step).inputType == 'AGGREGATE_FUNCTION' ||
                        getField(step).inputType == 'CURRENCY' ||
                        getField(step).inputType == 'FORMULA')
                    "
                  >
                    {{
                      getCurrencyFormat(rowData[getProp(step)], getField(step))
                    }}
                  </span>
                  <span v-else>
                    {{ rowData[getProp(step)] }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="d-flex"
            v-if="
              payload && payload.aggregations && payload.aggregations.length
            "
            style="float: right"
          >
            <div
              v-for="(aggregation, index) in payload.aggregations"
              :key="index"
              class="ml-4"
              style="font-size: x-small"
            >
              <b>{{ aggregation.label }}</b>
              <br />
              <span>{{ aggregationValues[row][aggregation.label] }}</span>
            </div>
          </div>
          <br />
        </div>
      </section>
    </vue-html2pdf>
    <complete-entity-data-popup
      :entityId="selectedChildEntityId"
      :entityDataId="selectedChildEntityDataId"
      :entityRefName="selectedChildEntityRefName"
      :primaryDataValue="selectedChildEntityPrimaryDataValue"
      :isMultiple="selectedChildEntityDataIsMultple"
      :isTitleVisible="false"
      v-if="showChildEntityDataDialog"
      @close="closeChildEntityDataDialog"
    ></complete-entity-data-popup>
    <dialog-component
      title="Send Notification"
      :visible="sendNotificationDialog"
      v-loading="emailConfigLoading"
      :containerWidth="getIsMobile ? '100%' : '52%'"
      @before-close="sendNotificationDialog = false"
      :isShowFooter="false"
    >
      <div v-if="payload.send_attachment_to">
        <EmailConfig
          :currentEntity="emailEntity"
          :isSendIndividual="true"
          :entityData="selectedNotifyEntity"
          :paramsContent="paramsData"
          :emailTemplateId="payload.attachment_email_template"
          :hideActions="true"
          v-on:closeNotificationDialog="closeSendNotificationDialog"
        >
        </EmailConfig>
      </div>
      <div v-else>
        <EmailSendBox
          :paramsContent="paramsData"
          v-on:closeNotificationDialog="closeSendNotificationDialog"
        ></EmailSendBox>
      </div>
    </dialog-component>

    <dialog-component
      :title="'View Fields'"
      :visible="dialogSettingsTableVisible"
      @before-close="dialogSettingsTableVisible = false"
      :containerWidth="'40%'"
    >
      <div class="m-tb-3">
        <el-input
          size="mini"
          v-model="columnSearchQuery"
          placeholder="Search columns"
          class="mb-3 ml-2"
          prefix-icon="el-icon-search"
        />
        <el-checkbox
          v-model="selectAllColumns"
          @change="toggleSelectAll"
          class="mb-3 ml-2"
        >
          Select All Columns
        </el-checkbox>
        <el-checkbox-group
          v-model="selectedColumnKeys"
          class="dialog-checkbox-group"
        >
          <el-checkbox
            v-for="column in filteredColumns"
            :key="
              column.key +
              (![undefined, null, ''].includes(column.filter)
                ? '@' + column.filter
                : '')
            "
            :label="
              column.key +
              (![undefined, null, ''].includes(column.filter)
                ? '@' + column.filter
                : '')
            "
          >
            {{ column.label }}
          </el-checkbox>
        </el-checkbox-group>
      </div>

      <span slot="footer" class="dialog-footer">
        <button
          class="btn btn-outline-primary btn-sm m-lr-1"
          type="button"
          @click="saveAndApply"
        >
          <i class="fa fa-rocket fa-fade"></i> &nbsp; Save & apply
        </button>
      </span>
    </dialog-component>

    <dialog-component
      :visible="detailedViewModalVisible"
      :title="detailedViewModalName"
      :containerWidth="getIsMobile ? '100%' : '40%'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @before-close="detailedViewModalVisible = false"
    >
      <div v-if="selectionView">
        <div v-if="!checkIsDividedFilterAdded || !click_options.length">
          No actions configured
        </div>
        <div
          v-for="(action, index) in click_options"
          :key="'action_' + index"
          style="width: 250px"
        >
          <el-link
            icon="el-icon-arrow-right"
            style="cursor: pointer"
            :underline="false"
            @click="executeAction(action)"
            class="pt-1 pb-1"
          >
            {{ action.name }}
          </el-link>
        </div>
      </div>
      <div v-else>
        <CustomDashboardTable
          v-if="
            detailedViewData &&
            detailedViewData.entity_id &&
            detailedViewModalVisible
          "
          :component="detailedViewData"
          :entity_id="detailedViewData.entity_id"
          :hide_options="true"
          :hide_expend="true"
          :data_ids="detailedViewData.data_ids"
          :hasAddPermission="detailedViewData.has_add_permission"
          :apply_only_data_ids="true"
        >
        </CustomDashboardTable>
      </div>
    </dialog-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import * as XLSX from "xlsx";
import moment from "moment";
import NavigationHelper from "@/mixins/navigationHelper";
import AutomationHelper from "@/mixins/AutomationHelper";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import * as mexp from "math-expression-evaluator";
import TemplateBuilderhelper from "@/mixins/templateBuilderHelper.js";
import EntityHelper from "@/mixins/EntitiesHelper";
import { postAPICall } from "../../helpers/httpHelper";
import { getAllCompanyTemplatesByPagination } from "@/repo/templatesRepo";
import { fetchGlobalVariables } from "@/repo/globalVariables";
import { fetchEntitiesByPagination, fetchEntityById } from "@/repo/entityRepo";

import VueHtml2pdf from "vue-html2pdf";
export default {
  watch: {
    "$route.params.entity_view_id": {
      async handler(entity_view_id) {
        await this.getNecessaryInfo(entity_view_id);
      },
    },
    columnSearchQuery() {
      if (this.selectAllColumns) {
        this.selectedColumnKeys = this.filteredColumns.map((column) =>
          column.key + ![undefined, null, ""].includes(column.filter)
            ? `@${column.filter}`
            : ""
        );
      }
    },
    selectedFileds: {
      async handler(data) {
        let newForm = {};
        this.payload.filter_fields.forEach((e) => {
          if (e.filters) {
            (e.filters || []).forEach((f) => {
              newForm[f.value_field] = data[f.value_field];
            });
          }
        });
        if (JSON.stringify(this.filterForm) !== JSON.stringify(newForm)) {
          (this.payload.filter_fields || []).forEach((filter) => {
            if (
              ["ENTITY", "RELATION"].includes(filter.type) &&
              filter.filters &&
              filter.filters.find(
                (e) =>
                  e.data_source == "self_field" || e.data_source == "TEMPLATE"
              ) &&
              this.filterForm[filter.key] == newForm[filter.key]
            ) {
              this.$set(
                this.selectedFileds,
                filter.key,
                filter.multiple ? ["all"] : "all"
              );
            }
          });
          this.fetchDependentEnityData();
          this.filterForm = {
            ...this.filterForm,
            ...JSON.parse(JSON.stringify(newForm)),
          };
        }
      },
      deep: true,
    },
    getGlobalFiltervalue: {
      handler() {
        if (this.checkGlobalFilter?.entity_id && this.isRelatedToGlobalFilter) {
          this.mapGlobalFilters();
          this.checkAndApplyFilters();
        }
      },
    },
  },
  props: [
    "hideTopbar",
    "filtersData",
    "entityViewId",
    "fromCustomDashboard",
    "index",
    "component",
    "customDashboardData",
    "hide_options",
  ],
  components: {
    EntityDetailedView: () => import("./EntityViews.vue"),
    CustomComponentEdit: () =>
      import("@/components/customDashboard/customComponentEdit.vue"),
    ViewTemplate: () => import("../templates/ViewTemplate.vue"),
    VueHtml2pdf,
    CompleteEntityDataPopup: () => import("./completeEntityDataPopup.vue"),
    EmailConfig: () => import("../notifications/emailConfig.vue"),
    EmailSendBox: () => import("../notifications/emailSendBox.vue"),
    CustomDashboardTable: () =>
      import("../customDashboard/customDashboardTable.vue"),
  },
  computed: {
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("entities", [
      "getEntityViewsData",
      "getEntityRecordsForTable",
      "getEntityViewsPDFExport",
      "getSentEntityDataNotificationStatus",
      "getSentEntityNotificationStatusErrors",
    ]),
    ...mapGetters("entityViews", [
      "getEntityViewById",
      "getEntityViewRedirectRoute",
    ]),
    ...mapGetters("auth", [
      "getDefaultDateFormat",
      "getGlobalFiltervalue",
      "getActiveWorkspace",
      "getActiveContactType",
    ]),
    ...mapGetters("company", ["getCompanyDetails"]),
    ...mapGetters("templatesData", ["getDownloadUrl", "getDownloadError"]),
    ...mapGetters("applicationUsersPermission", ["getEntityPermission"]),
    ...mapGetters("iFrame", ["getIframeUrlInfo"]),
    ...mapGetters("menuManagementV2", ["getEntityMenu"]),
    getFiltersData() {
      let filters = {};
      this.payload.filter_fields.map((fd) => {
        filters[fd.label] =
          ["ENTITY", "RELATION"].includes(fd.type) &&
          ["all", ["all"]].includes(this.selectedFileds[fd.key])
            ? fd.multiple
              ? ["ALL"]
              : "ALL"
            : this.selectedFileds[fd.key];
      });
      return filters;
    },
    filteredColumns() {
      const searchQueryLower = this.columnSearchQuery.toLowerCase();
      return this.removeHiddenColumns(this.payload.main_columns || []).filter(
        (column) =>
          column.label.toLowerCase().includes(searchQueryLower) &&
          !(column.divide_data_by && column.field_type == "SUM")
      );
    },
    getStyle() {
      return this.fromCustomDashboard
        ? `border: 1px solid #eaeaea;border-radius: 5px;min-height: 300px !important;margin-bottom: 0px;margin-top: 0px;box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1) !important;`
        : "";
    },
    checkGlobalFilter() {
      if (this.getCompanyDetails?.global_filters) {
        let filter = this.getCompanyDetails.global_filters.find(
          (e) => e.include_in_topbar
        );
        return filter ? filter : null;
      }
      return null;
    },
    checkLoading() {
      return (key) => {
        return this.loadingFilters[key];
      };
    },
    getDefaultSort() {
      let defaultSortColumn = (this.payload.main_columns || []).find(
        (e) => e.is_default_sort
      );
      if (defaultSortColumn) {
        return {
          prop: this.getProp(defaultSortColumn),
          order: defaultSortColumn.sort_order,
        };
      }
      // if (this.payload?.main_columns && this.payload.main_columns[0]) {
      //   return {
      //     prop: this.getProp(this.payload.main_columns[0]),
      //     order: "descending",
      //   };
      // }
      return {};
    },
    checkAttachmentField() {
      if (
        this.payload &&
        ((this.payload.send_attachment_to &&
          this.selectedFileds?.[this.payload.send_attachment_to] &&
          this.selectedFileds?.[this.payload.send_attachment_to] !== "all" &&
          (this.payload.filter_fields || []).find(
            (e) => e.key == this.payload?.send_attachment_to && !e.multiple
          )) ||
          !this.payload.send_attachment_to)
      ) {
        return true;
      }
      return false;
    },
    getFilterFields() {
      return this.isApplicationUserSide
        ? (this.payload?.filter_fields || []).filter(
            (fd) => fd.entity_id != this.getActiveContactType.contact_type._id
          )
        : this.payload?.filter_fields;
    },
    tableColumns() {
      return this.removeHiddenColumns(this.payload.main_columns || []).map(
        (column, index) => ({
          id: index,
          label: column.label,
          key: column.key,
          is_visible: column.is_visible !== false,
        })
      );
    },
    getCurrentMonthDays() {
      const daysArray = [];
      const date = new Date(this.currentDate);
      const year = date.getFullYear();
      const month = date.getMonth();

      const daysInMonth = new Date(year, month + 1, 0).getDate();

      for (let day = 1; day <= daysInMonth; day++) {
        const currentDay = new Date(year, month, day);
        daysArray.push({
          dayName: currentDay.toLocaleString("default", { weekday: "short" }),
          date: day,
          month: month + 1,
        });
      }

      return daysArray;
    },
    checkIsDividedFilterAdded() {
      return this.payload.main_columns.find((e) => e.divide_data_by) || null;
    },
    applicationUserFormbuilderMenuItems() {
      if (this.getEntityMenu?.menu_list) {
        let menuItems = this.getEntityMenu.menu_list.filter(
          (menu) =>
            menu?.feature == "form_builder" &&
            menu?.formbuilder_id?.steps.find((step) => step?.entity)
        );
        return menuItems;
      }
      return null;
    },
  },
  mixins: [
    NavigationHelper,
    AutomationHelper,
    CustomDashboardConfig,
    TemplateBuilderhelper,
    EntityHelper,
  ],
  data() {
    return {
      loading: false,
      data: {},
      isFilterOpen: true,
      entityDataMapping: {},
      selectedFileds: {},
      appliedFilters: {},
      appliedFiltersNames: {},
      selectedEntityFields: [],
      updateComponentData: null,
      openDialogVisible: false,
      globalVariablesData: [],
      payload: {},
      dialogSettingsTableVisible: false,
      selectedColumnKeys: [],
      selectAllColumns: false,
      columnSearchQuery: "",
      pickerOptions: [
        {
          label: "Today",
          value: "today",
        },
        {
          label: "Yesterday",
          value: "yesterday",
        },
        {
          label: "Recent",
          value: "recent",
        },
        {
          label: "Past 30 days",
          value: "past_30_days",
        },
        {
          label: "Last week",
          value: "last_week",
        },
        {
          label: "Last week-to-date",
          value: "last_week_to_date",
        },
        {
          label: "This week",
          value: "this_week",
        },
        {
          label: "This week-to-date",
          value: "this_week_to_date",
        },
        {
          label: "Next week",
          value: "next_week",
        },
        {
          label: "Last month",
          value: "last_month",
        },
        {
          label: "Last month-to-date",
          value: "last_month_to_date",
        },
        {
          label: "This month",
          value: "this_month",
        },
        {
          label: "This month-to-date",
          value: "this_month_to_date",
        },
        {
          label: "Next month",
          value: "next_month",
        },
        {
          label: "Last quarter",
          value: "last_quarter",
        },
        {
          label: "Last quarter-to-date",
          value: "last_quarter_to_date",
        },
        {
          label: "This quarter",
          value: "this_quarter",
        },
        {
          label: "This quarter-to-date",
          value: "this_quarter_to_date",
        },
        {
          label: "Next quarter",
          value: "next_quarter",
        },
        {
          label: "Last year",
          value: "last_year",
        },
        {
          label: "Last year-to-date",
          value: "last_year_to_date",
        },
        {
          label: "This year",
          value: "this_year",
        },
        {
          label: "This year-to-date",
          value: "this_year_to_date",
        },
        {
          label: "Next year",
          value: "next_year",
        },
      ],
      default_date: "this_year",
      currentEntity: {},
      entitytemplateColorRules: {},
      filterForm: {},
      dependentFilters: [],
      loadingFilters: {},
      refresh: false,
      templateFieldsObj: {},
      entityFieldsObj: {},
      detailedViewModal: false,
      detailedViewFilters: [],
      detailedEntityViewId: null,
      detailedEntityViewTitle: "",
      entityViewData: {},
      isRelatedToGlobalFilter: false,
      loadingText: "Fetching data...",
      pdfExport: false,
      search_string: "",
      isApplicationUserSide: false,
      aggregationValues: {},
      otherEntitiesAllFields: {},
      otherEntities: {},
      primaryFilter: {},
      showChildEntityDataDialog: false,
      selectedChildEntityDataId: "",
      selectedChildEntityId: "",
      selectedChildEntityRefName: "",
      selectedChildEntityPrimaryDataValue: "",
      selectedChildEntityDataIsMultple: false,
      emailConfigLoading: false,
      emailEntity: null,
      paramsData: {},
      selectedNotifyEntity: null,
      sendNotificationDialog: false,
      rowColors: {},
      paginationData: {},
      isSearchingData: false,
      downloadingFile: false,
      currentDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      detailedViewModalName: "Details",
      detailedViewModalVisible: false,
      detailedViewData: null,
      selectionView: false,
      click_options: [],
      entityUserInfoData: {},
    };
  },
  async mounted() {
    if (
      [
        "application-user-page-dashboard",
        "ApplicationUserEntityViews",
        "application-user-dashboard",
      ].includes(this.$route.name)
    ) {
      this.isApplicationUserSide = true;
    }
    if (this.entityViewId) {
      await this.getNecessaryInfo(this.entityViewId);
    } else if (this.$route.params.entity_view_id) {
      await this.getNecessaryInfo(this.$route.params.entity_view_id);
    }
  },
  methods: {
    removeHtmlTags(html) {
      return html ? html.replace(/<\/?[^>]+(>|$)/g, "") : "";
    },
    decodeHtmlEntities(text) {
      let textarea = document.createElement("textarea");
      textarea.innerHTML = text;
      return textarea.value;
    },
    cleanText(html) {
      if (!html) return "";
      const strippedText = this.removeHtmlTags(html);
      return this.decodeHtmlEntities(strippedText);
    },
    executeAction(action) {
      switch (action.action) {
        case "ADD":
          if (this.isApplicationUserSide) {
            let item = (this.applicationUserFormbuilderMenuItems || []).find(
              (e) =>
                (e?.formbuilder_id?.steps || []).some((s) =>
                  this.payload?.other_entities?.some(
                    (o) => o.entity_id === s.entity
                  )
                )
            );
            this.$router.push({
              path: `/ap/fbd/${item.formbuilder_id.code}/${item.formbuilder_id.steps[0].slug}/${item.formbuilder_id._id}?user_id=${this.getAuthenticatedUser._id}`,
            });
          } else {
            this.addEntityData(action.entity_id);
          }
          break;
        case "EDIT":
          this.editEntityData(action);
          break;
        case "VIEW":
          this.editEntityData(action);
          break;
      }
    },
    generateSuggestionForm(entity) {
      let form = {};
      (entity.templates || []).forEach((temp) => {
        let templateFields = this.templateFieldsObj[temp.template_id] || [];
        let tempObj = {};
        templateFields.map((field) => {
          let key =
            field && field.key.includes("#")
              ? field.key.split("#")[1]
              : field.key;
          if (field.inputType == "ENTITY") {
            if (this.payload.entity_id == field.entity_id) {
              tempObj[key] = this.detailedViewData?.row?.parentDataId;
            }
          } else if (
            field.inputType == "DATE" &&
            field.label == this.checkIsDividedFilterAdded?.label
          ) {
            if (
              this.detailedViewData?.day?.date &&
              this.detailedViewData?.day?.month
            ) {
              if (
                this.detailedViewData?.row?.slotFrequencyValue &&
                this.detailedViewData?.row?.slotStartDateValue
              ) {
                let currentDate = new Date(
                  new Date(this.currentDate).getFullYear(),
                  this.detailedViewData.day.month - 1,
                  this.detailedViewData.day.date
                );
                let start = new Date(
                  this.detailedViewData.row.slotStartDateValue
                );
                let frequency = Number(
                  this.detailedViewData.row.slotFrequencyValue
                );

                let end = new Date(start);

                if (frequency === 7) {
                  end.setDate(start.getDate() + (6 - start.getDay()));
                } else if (frequency === 30 || frequency === 31) {
                  end.setDate(1);
                } else if (frequency === 15) {
                  if (end.getDate() >= 15) {
                    end.setDate(15);
                  } else {
                    end.setDate(1);
                  }
                }

                let isBetween = currentDate >= start && currentDate <= end;
                if (isBetween) {
                  tempObj[key] = start.toISOString();
                } else {
                  if (frequency === 7) {
                    tempObj[key] = this.getWeekStartDate(currentDate);
                  } else if (frequency === 30 || frequency === 31) {
                    tempObj[key] = new Date(
                      currentDate.setDate(1)
                    ).toISOString();
                  } else if (frequency === 15) {
                    if (end.getDate() >= 15) {
                      tempObj[key] = new Date(
                        currentDate.setDate(15)
                      ).toISOString();
                    } else {
                      tempObj[key] = new Date(
                        currentDate.setDate(1)
                      ).toISOString();
                    }
                  }
                }
              } else {
                tempObj[key] = new Date(
                  new Date(this.currentDate).getFullYear(),
                  this.detailedViewData.day.month - 1,
                  this.detailedViewData.day.date
                ).toISOString();
              }
            }
          }
        });
        form[temp.template_id] = tempObj;
      });
      return form;
    },
    getWeekStartDate(date) {
      let startDate = new Date(date);
      let day = startDate.getDay();

      startDate.setDate(startDate.getDate() - day);

      return startDate.toISOString();
    },
    editEntityData(action) {
      let { month, date, dayName } = this.detailedViewData?.day || {};
      if (
        this.detailedViewData?.row[
          "action/" + month + "-" + date + "-" + dayName + "/" + action.column
        ]
      ) {
        this.openEntityDataScreen(
          action.entity_id,
          this.detailedViewData?.row[
            "action/" + month + "-" + date + "-" + dayName + "/" + action.column
          ],
          action.action
        );
      } else {
        this.$message.warning({
          showClose: true,
          message: "No data found",
          center: true,
        });
      }
    },
    addEntityData(entity_id) {
      let selectedEntity = this.entityFieldsObj[entity_id];
      if (!selectedEntity) {
        this.$message.error({
          showClose: true,
          message:
            "Something went wrong. Please refresh the screen and try again",
          center: true,
        });
        return;
      }
      let suggestionForm = this.generateSuggestionForm(selectedEntity);
      this.$store.commit("templates/setSuggestionFormData", suggestionForm, {
        root: true,
      });
      this.openEntityDataScreen(entity_id);
    },
    openSlots(day, row) {
      this.selectionView = true;
      this.detailedViewData = null;
      let dataIds = [];
      let key = day.month + "-" + day.date + "-" + day.dayName;
      if (row[key + "@data"]) {
        dataIds = row[key + "@data"];
      }
      this.detailedViewData = {
        day,
        row,
        dataIds,
      };
      this.click_options = [];
      this.click_options = (
        this.checkIsDividedFilterAdded?.click_options || []
      ).filter((e) => row?.[key + "$rulesResults"]?.[e.when]);
      this.detailedViewModalVisible = true;
    },
    checkIsDateSelected(filter) {
      return (
        this.checkIsDividedFilterAdded &&
        this.checkIsDividedFilterAdded.linked_entity_id +
          "#" +
          this.checkIsDividedFilterAdded.template_id +
          "#" +
          this.checkIsDividedFilterAdded.divide_data_by ==
          filter.key
      );
    },
    openSettingsDialog() {
      this.dialogSettingsTableVisible = true;
      const visibleColumns = this.removeHiddenColumns(
        this.payload.main_columns || []
      );
      this.selectedColumnKeys = this.selectedColumnKeys.filter((key) =>
        visibleColumns.some((col) => {
          let [k, f] = key.includes("@") ? key.split("@") : [key, ""];
          let [ck, cf] = ![undefined, null, ""].includes(col.filter)
            ? [col.key, col.filter]
            : [col.key, ""];
          if (k == ck && f == cf) {
            return true;
          }
          return false;
        })
      );
    },

    saveAndApply() {
      if (this.selectedColumnKeys.length === 0) {
        this.$message({
          message: "Please select at least one column before saving.",
          type: "warning",
        });
        return;
      }
      this.dialogSettingsTableVisible = false;
    },
    toggleSelectAll(checked) {
      if (checked) {
        this.selectedColumnKeys = this.filteredColumns.map(
          (column) =>
            column.key +
            (![undefined, null, ""].includes(column.filter)
              ? `@${column.filter}`
              : "")
        );
      } else {
        this.selectedColumnKeys = [];
      }
    },
    async loadMore(row) {
      let currentPage = this.paginationData[row].tablePage || 1;
      let pageTotal = this.paginationData[row].tableDataPages || 1;
      if (currentPage >= pageTotal) {
        return;
      }
      this.setPaginations(row, true);
      await this.fetchData([], [], row);
    },
    getColumnStyle(step, row) {
      if (this.rowColors?.[row.parentDataId]?.[step.key]) {
        return this.rowColors?.[row.parentDataId]?.[step.key].join(";");
      }
      return "";
    },
    openSendNotification() {
      if (this.payload.send_attachment_to) {
        this.selectedNotifyEntity = {
          _id: this.selectedFileds?.[this.payload.send_attachment_to],
        };
        let field = (this.payload.filter_fields || []).find(
          (e) => e.key == this.payload?.send_attachment_to && !e.multiple
        );
        this.emailEntity = this.otherEntities[field.entity_id];
      }
      this.sendNotificationDialog = true;
    },
    async closeSendNotificationDialog(params) {
      this.emailConfigLoading = true;
      let urls = await this.exportToPDF(true);
      if (!urls || !urls.length) {
        this.$notify({
          title: "Error",
          message: "Error while generating PDF",
          type: "error",
        });
        this.emailConfigLoading = false;
        this.loading = false;
        return;
      }
      params.attachment_links = urls;
      params.company_id = this.currentEntity?.company_id;
      let endPoint = "/entities-data/users/send-notification";
      if (this.payload?.send_attachment_to) {
        endPoint = "/entities-data/send-notification";
      }
      let response = await postAPICall("POST", endPoint, params);
      if (response) {
        this.emailConfigLoading = false;
        this.$notify({
          title: "Success",
          message: "Sending mail(s) initiated",
          type: "success",
        });
      } else {
        this.emailConfigLoading = false;
        this.$notify({
          title: "Error",
          message: "Error while sending mails",
          type: "error",
        });
      }
      this.sendNotificationDialog = false;
    },
    closeChildEntityDataDialog() {
      this.showChildEntityDataDialog = false;
    },
    checkChildView(step) {
      return (
        step?.field_type == "DISPLAY" &&
        step?.main_entity_field &&
        step?.allow_view_details
      );
    },
    openChildData(data, step) {
      if (this.checkChildView(step) && data.parentDataId) {
        this.showChildEntityDataDialog = false;
        this.selectedChildEntityDataIsMultple = false;

        this.selectedChildEntityDataId = data.parentDataId;

        this.selectedChildEntityId = this.currentEntity?._id;

        this.selectedChildEntityRefName = this.payload.name;
        this.selectedChildEntityPrimaryDataValue = this.currentEntity?.name;
        this.showChildEntityDataDialog = true;
      }
    },
    showExport() {
      return Object.values(this.data).flatMap((dt) => dt).length ? true : false;
    },
    getData(row) {
      // if (this.search_string != "") {
      //   return row.filter((dt) =>
      //     Object.values(dt)
      //       .map((val) => {
      //         return val.toString().toLowerCase();
      //       })
      //       .some((val) => val.includes(this.search_string.toLowerCase()))
      //   );
      // }
      return row;
    },
    getPrimaryFilterLabel(row) {
      if (row == "all") {
        return "All";
      }
      if (["ENTITY", "RELATIOIN"].includes(this.primaryFilter.type)) {
        return this.getLabel(
          this.allEntityDataList(this.primaryFilter.entity_id).find(
            (e) => e._id == row
          ),
          1,
          this.primaryFilter.entity_id
        );
      }
      return row;
    },
    changeFilterData(filter) {
      if (
        filter.multiple &&
        this.selectedFileds[filter.key].length > 1 &&
        this.selectedFileds[filter.key].includes("all")
      ) {
        this.selectedFileds[filter.key].splice(
          this.selectedFileds[filter.key].indexOf("all"),
          1
        );
      }
    },
    getDataTableField(tempId, key) {
      return this.templateFieldsObj?.[tempId]
        ?.filter(
          (fd) =>
            fd.input_type == "DATA_TABLE" || fd.input_type == "ENTITY_TABLE"
        )
        ?.find(
          (fd) => fd.data_table_columns.filter((dc) => dc.key == key).length
        )
        ?.data_table_columns.find((dc) => dc.key == key);
    },
    getAggregationValue(aggregation, filter) {
      if (aggregation?.result_type == "PERCENTAGE") {
        return (
          (!isNaN(this.aggregationValues[filter][aggregation.label])
            ? this.aggregationValues[filter][aggregation.label]
            : "-") + "%"
        );
      } else if (aggregation?.result_type == "CURRENCY") {
        let field = aggregation.selected_fields.find(
          (fd) => fd.result_type == "CURRENCY"
        );
        let column = this.payload.main_columns.find(
          (fd) =>
            fd.template_id == field.template_id &&
            fd.key == field.key &&
            fd?.filter == field?.filter
        );
        let value = this.getCurrencyFormat(
          this.aggregationValues[filter][aggregation.label],
          this.getField(column)
        );
        return !isNaN(this.aggregationValues[filter][aggregation.label])
          ? value
          : "-";
      } else {
        return !isNaN(this.aggregationValues[filter][aggregation.label])
          ? this.aggregationValues[filter][aggregation.label]
          : "-";
      }
    },
    async updateComponent() {
      this.updateComponentData.component_name =
        this.updateComponentData.component_name.trim();
      if (!this.updateComponentData.component_name) {
        this.$message.error("Please enter Entity view name");
        return;
      }
      let ex = this.customDashboardData.allTableComponents.filter(
        (e, i) =>
          i !== this.index &&
          e.component_name.toLowerCase() ==
            this.updateComponentData.component_name.toLowerCase()
      );
      if (ex && ex.length) {
        this.$notify.error({
          title: "Error",
          message: "Component name already exists",
        });
        return;
      }
      this.openDialogVisible = false;
      if (
        this.updateComponentData.entity_view_id !==
        this.component.entity_view_id
      ) {
        this.component = { ...this.updateComponentData };
        await this.getNecessaryInfo(this.component.entity_view_id);
      } else {
        this.component = { ...this.updateComponentData };
      }
      this.$emit("viewUpdate", {
        index: this.index,
        data: this.component,
      });
    },
    editComponent() {
      this.updateComponentData = { ...this.component };
      this.openDialogVisible = true;
    },
    deleteComponent() {
      this.$confirm("Are you sure to delete the Entity View?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$emit("deleteComponent", this.index);
      });
    },
    expandEntityView() {
      this.component.isExpandTable = !this.component.isExpandTable;
      this.$emit("expendTable", this.index);
    },
    search() {
      this.isSearchingData = true;
      this.checkAndApplyFilters();
      // this.loading = true;
      // this.searchData = [];
      // this.searchData = this.data.filter((dt) =>
      //   Object.values(dt)
      //     .map((val) => {
      //       return val.toString().toLowerCase();
      //     })
      //     .some((val) => val.includes(this.search_string.toLowerCase()))
      // );
      // this.fetchAggregationValues();
      // this.loading = false;
    },
    async downloadFile(rawData) {
      let data = JSON.parse(rawData);
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      await fetch(this.getDownloadUrl, {
        method: "GET",
        responseType: "blob",
      })
        .then((res) => res.blob())
        .then((response) => {
          var fileURL = window.URL.createObjectURL(response);
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      if (this.getDownloadUrl) {
        // window.open(this.getDownloadUrl, "_blank");
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getDownloadError || "Error while downloading",
        });
      }
    },
    removeHiddenColumns(colums) {
      return colums.filter((e) => {
        if (!e?.is_hide_column) {
          return e;
        }
      });
    },
    getSelectedColumns() {
      const visibleColumns = this.filteredColumns;
      if (this.selectedColumnKeys.length === 0) {
        return visibleColumns;
      }
      return visibleColumns.filter((column) => {
        let key =
          column.key +
          (![undefined, null, ""].includes(column.filter)
            ? `@${column.filter}`
            : "");
        if (this.selectedColumnKeys.includes(key)) {
          return true;
        }
        return false;
      });
    },
    openDetailedView(index, step, filter) {
      let data = this.entityViewData[filter][index];
      if (step?.allow_view_details && step?.detailed_entity_view_id && data) {
        this.detailedEntityViewId = step.detailed_entity_view_id;
        this.detailedViewFilters = [
          {
            entity_id: step.linked_entity_id,
            value:
              data[
                step.linked_entity_id + "#" + step.template_id + "#" + step.key
              ] &&
              data[
                step.linked_entity_id + "#" + step.template_id + "#" + step.key
              ].length
                ? data[
                    step.linked_entity_id +
                      "#" +
                      step.template_id +
                      "#" +
                      step.key
                  ].flatMap((e) => e._id)
                : [],
          },
          {
            entity_id: this.currentEntity._id,
            value: [data._id],
          },
        ];
        this.detailedEntityViewTitle = step.detailed_view_title
          ? step.detailed_view_title
          : this.getPrimaryStepDetails(data);
        this.detailedViewModal = true;
      }
    },
    getPrimaryStepDetails(data) {
      let text = "",
        count = 0;
      (this.payload.main_columns || []).forEach((step) => {
        if (
          step.main_entity_field &&
          step.field_type == "DISPLAY" &&
          count < 3
        ) {
          text =
            text +
            (step.label + ": " + this.getFieldValue(data, step)) +
            (count < 2 ? "," : "");
          count++;
        }
      });
      return text;
    },
    closeDetailedView() {
      this.detailedEntityViewId = null;
      this.detailedViewFilters = [];
      this.detailedEntityViewTitle = "";
      this.detailedViewModal = false;
    },
    checkIsDateField(value) {
      if ([undefined, null, ""].includes(value)) {
        return "";
      }
      const isoDateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z$/;
      const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (isoDateTimeRegex.test(value) || isoDateRegex.test(value)) {
        const format = this.getDefaultDateFormat
          ? this.getDefaultDateFormat
          : "MM-DD-YYYY";
        return moment(value).format(format);
      }
      if (typeof value == "object") {
        return value.join(", ");
      }
      return value;
    },
    sumOfArray(row, step, key) {
      let array = this.getOtherEntityFieldValue(row, step, key);
      if (array && array.length) {
        array = array
          .filter((e) => e[key])
          .reduce(
            (accumulator, currentValue) => [
              ...accumulator,
              ...currentValue[key],
            ],
            []
          );
        //remove duplicate data
        array = array.filter((e) => !e.newlyAddedData);
        const sumWithInitial = array
          .filter(
            (e) => e[step.key]
            // (!step.apply_parent_filter ||
            //   (step.apply_parent_filter && e["parentDataId"] == row._id))
          )
          .reduce(
            (accumulator, currentValue) =>
              accumulator + Number(currentValue[step.key] || 0),
            0
          );
        return sumWithInitial || 0;
      }
      return 0;
    },
    getOtherEntityFieldValue(row, step, key) {
      if (row && step.linked_entity_id && key) {
        let value;
        if (step.selected_date_offset) {
          value =
            row[
              step.selected_date_offset +
                "#" +
                step.linked_entity_id +
                "#" +
                step.template_id +
                "#" +
                key +
                (![undefined, null, ""].includes(step.filter)
                  ? "@" + step.filter
                  : "")
            ];
        } else {
          value =
            row[
              step.linked_entity_id +
                "#" +
                step.template_id +
                "#" +
                key +
                (![undefined, null, ""].includes(step.filter)
                  ? "@" + step.filter
                  : "")
            ];
        }
        if (step.divide_data_by) {
          value =
            row[
              step.linked_entity_id +
                "#" +
                step.template_id +
                "#" +
                step.divide_data_by
            ];
        }
        if (value) {
          return value;
        }
        return step.default_value
          ? step.default_value
          : step.field_type == "SUM" ||
            step.field_type == "AVG" ||
            step.field_type == "COUNT"
          ? 0
          : "";
      }
      return step.default_value
        ? step.default_value
        : step.field_type == "SUM" ||
          step.field_type == "AVG" ||
          step.field_type == "COUNT"
        ? 0
        : "";
    },
    getTFields(temp) {
      let fields = [];
      let data = JSON.parse(JSON.stringify(temp));
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        e.key = data._id + "#" + e.key;
        e.template_name = data.name;
        e.data_type =
          e.validations && e.validations.type ? e.validations.type : "STRING";
        return e;
      });
      return fields;
    },
    getFieldOptions(filter) {
      if (filter.key && filter.key.includes("#")) {
        let key = filter?.main_entity_field
          ? filter.key
          : filter.key.split("#")[1] + "#" + filter.key.split("#")[2];
        let allFields =
          (filter.main_entity_field
            ? this.selectedEntityFields
            : this.otherEntitiesAllFields[filter.key.split("#")[0]]) || [];
        let filterField = allFields.find((fd) => fd.key == key);
        if (filterField?.input_type == "ENTITY_VARIABLE") {
          let eVariable = allFields.find(
            (fd) =>
              fd.key ==
              filterField.template_id +
                "#" +
                filterField.global_variable_entity_parent
          );
          if (eVariable.entity_id == this.payload.entity_id) {
            let field = this.selectedEntityFields.find(
              (fd) => fd.key == filterField.global_variable_entity_field
            );
            return field?.options || [];
          } else {
            let field = this.otherEntitiesAllFields[eVariable.entity_id].find(
              (fd) => fd.key == filterField.global_variable_entity_field
            );
            return field?.options || [];
          }
        }
        return filterField?.options || [];
      }
      return [];
    },
    async getRequiredTemplates() {
      let templateIds = [];
      this.payload.filter_fields.forEach((e) => {
        if (e?.type == "SELECT" && e.key && e.key.includes("#")) {
          let tempId = e?.main_entity_field
            ? e.key.split("#")[0]
            : e.key.split("#")[1];
          templateIds.push(tempId);
        }
      });
      this.templateFieldsObj = {};
      this.entityFieldsObj = {};
      let entityIds = [];
      (this.checkIsDividedFilterAdded?.click_options || []).map((e) => {
        entityIds.push(e.entity_id);
      });
      let promises = [
        // this.$store.dispatch("companyTemplates/getAllCompanyTemplates", {
        //   get_all: true,
        //   include_templates: templateIds,
        //   populate_data: true,
        // }),
        getAllCompanyTemplatesByPagination({
          get_all: true,
          include_templates: templateIds,
          populate_data: true,
        }),
        fetchGlobalVariables({
          get_all: true,
          company_id: this.company_id,
        }),
      ];
      if (entityIds.length) {
        promises.push(
          fetchEntitiesByPagination({
            existed_entity_ids: entityIds,
          })
        );
      }
      const [getAllCompanyTemplatesData, globalVariables, entities] =
        await Promise.all(promises);
      if (entities?.data) {
        (entities.data || []).forEach((entity) => {
          this.entityFieldsObj[entity._id] = entity;
        });
      }
      if (globalVariables?.data) {
        this.globalVariablesData = [...globalVariables.data];
      }
      if (getAllCompanyTemplatesData?.data) {
        (getAllCompanyTemplatesData.data || []).forEach((template) => {
          this.templateFieldsObj[template._id] = this.getTFields(template);
        });
      }
    },
    checkAndGetFilters() {
      this.payload.filter_fields.forEach((e) => {
        if (e.filters && e.filters.length) {
          (e.filters || []).forEach((f) => {
            if (this.dependentFilters.map((e) => e.key).indexOf(e.key) == -1) {
              this.dependentFilters.push(e);
            }
            this.filterForm[f.value_field] = this.selectedFileds[f.value_field];
          });
        }
      });
    },
    checkIsNumberField(step) {
      if (
        step.field_type == "SUM" ||
        step.field_type == "FORMULA" ||
        step.field_type == "AVG" ||
        step.field_type == "COUNT"
      ) {
        return true;
      }
      let field = this.selectedEntityFields.find(
        (e) => e.key && e.key.split("#")[1] && e.key.split("#")[1] == step.key
      );
      let types = ["CURRENCY", "NUMBER", "AGGREGATE_FUNCTION"];
      if (
        field &&
        field.inputType &&
        (types.indexOf(field.inputType) !== -1 ||
          (field.inputType == "FORMULA" &&
            types.indexOf(field.result_type) !== -1))
      ) {
        return true;
      }
      return false;
    },
    async getNecessaryInfo(id) {
      this.$store.commit("templates/setSuggestionFormData", null, {
        root: true,
      });
      const response = await postAPICall(
        "GET",
        `/entity-view-configuration/${id}`
      );
      if (this.fromCustomDashboard) {
        this.isFilterOpen = false;
      }
      if (response?.data) {
        if (
          response?.data?.printing_document ||
          response?.data?.printing_template
        ) {
          this.pdfExport = true;
        }
        this.payload = { ...response.data };
        this.checkAndGetFilters();
        this.reset();
        this.setDatePickerDate(this.default_date, true);
        this.mapGlobalFilters();
        this.mapEntityViewFiltersData();
        await Promise.all([
          this.fetchFilterEntityData(),
          this.getRequiredTemplates(),
        ]);
        await this.checkAndApplyFilters();
      }
    },
    mapGlobalFilters() {
      this.isRelatedToGlobalFilter = false;
      if (this.checkGlobalFilter?.entity_id && this.getGlobalFiltervalue) {
        this.payload.filter_fields.forEach((e) => {
          if (
            ["ENTITY", "RELATION"].includes(e?.type) &&
            e.entity_id == this.checkGlobalFilter?.entity_id
          ) {
            this.isRelatedToGlobalFilter = true;
            if (e?.multiple) {
              this.$set(this.selectedFileds, e.key, [
                this.getGlobalFiltervalue,
              ]);
            } else {
              this.$set(this.selectedFileds, e.key, this.getGlobalFiltervalue);
            }
          }
        });
      }
    },
    mapEntityViewFiltersData() {
      if (
        this.filtersData &&
        this.filtersData.length &&
        this.payload.filter_fields.length
      ) {
        this.payload.filter_fields.forEach((filter) => {
          if (
            ["ENTITY", "RELATION"].includes(filter?.type) &&
            filter?.entity_id
          ) {
            let dataObj = this.filtersData.find(
              (e) => e.entity_id == filter.entity_id
            );
            if (dataObj?.value) {
              this.selectedFileds[filter.key] = dataObj.value;
            }
          }
        });
      }
    },
    getFormulaValue(data, field) {
      if (field?.selected_fields && field.selected_fields.length) {
        let expr = "";
        field.selected_fields.forEach((e) => {
          if (e.type == "FIELD") {
            let v = this.getValue(data, e);
            let checkIsGlobalvariable = this.selectedEntityFields.find(
              (el) =>
                el.key && el.key.split("#")[1] && el.key.split("#")[1] == e.key
            );
            if (
              checkIsGlobalvariable &&
              (checkIsGlobalvariable.inputType == "DATE" ||
                checkIsGlobalvariable.result_type == "DATE")
            ) {
              v = this.dateDifference("01-01-0001", v, field.result_type);
            }
            expr = expr + v;
          } else if (
            e.type == "OPERATOR" ||
            e.type == "PARENTHESES" ||
            e.type == "NUMBER"
          ) {
            expr = expr + e.value;
          } else if (e.type == "CURRENT_DATE") {
            expr =
              expr +
              this.dateDifference("01-01-0001", moment(), field.result_type);
          }
        });
        try {
          let calculatedValue = mexp.eval(expr);
          if (isNaN(calculatedValue)) {
            return 0;
          }
          let roundedValue = calculatedValue.toFixed(2);
          roundedValue = parseFloat(roundedValue);
          return roundedValue;
        } catch (err) {
          return 0;
        }
      }
      return 0;
    },
    dateDifference(startDate, endDate, resultType = "DAYS") {
      const startMoment = moment(startDate);
      const endMoment = moment(endDate);

      switch (resultType.toUpperCase()) {
        case "DAYS":
          return endMoment.diff(startMoment, "days");
        case "MONTHS":
          return endMoment.diff(startMoment, "months");
        case "YEARS":
          return endMoment.diff(startMoment, "years");
        default:
          return 0;
      }
    },
    getValue(data, field) {
      let step = this.payload.main_columns.find((e) => {
        if (
          e.key == field.key &&
          (field?.filter == e?.filter || (!field?.filter && !e?.filter))
        ) {
          if (!field.template_id && !field.selected_date_offset) {
            return true;
          } else if (
            field.selected_date_offset &&
            field.selected_date_offset == e.selected_date_offset
          ) {
            if (!field.template_id || field.template_id == e.template_id) {
              return true;
            }
          } else if (
            field.template_id &&
            field.template_id == e.template_id &&
            !e.selected_date_offset
          ) {
            return true;
          }
        }
      });
      if (step) {
        if (step?.field_type == "DISPLAY") {
          return this.getFieldValue(data, step);
        } else if (step?.field_type == "FORMULA") {
          return this.getFormulaValue(data, step);
        }
        let columnKey = this.getProp(step);
        return data[columnKey] || 0;
      }
      return 0;
    },
    setDatePickerDate(value, addValue) {
      let data = this.getDatePickerHelper1(value);
      this.payload.filter_fields.forEach((filter) => {
        if (this.checkIsDateSelected(filter)) {
          this.$set(this.selectedFileds, filter.key, this.currentDate);
        } else {
          if (filter.type == "DATE" && filter.filter_type == "DATE_RANGE") {
            this.$set(this.selectedFileds, filter.key, data.value);
          }
          if (
            filter.type == "DATE" &&
            filter.filter_type == "PICKER_OPTIONS" &&
            addValue
          ) {
            this.$set(this.selectedFileds, filter.key, value);
          }
        }
      });
    },
    getProp(step) {
      if (step?.linked_entity_id && step?.template_id && step?.key) {
        if (
          step?.selected_date_offset &&
          (step.field_type == "SUM" ||
            step.field_type == "AGGREGATION_SUM" ||
            step.field_type == "AVG" ||
            step.field_type == "COUNT")
        ) {
          return (
            step.selected_date_offset +
            "#" +
            step.linked_entity_id +
            "#" +
            step.template_id +
            "#" +
            step.key +
            (![undefined, null, ""].includes(step.filter)
              ? "@" + step.filter
              : "") +
            (step.divide_data_by ? "/" + step.divide_data_by : "")
          );
        }
        return (
          step.linked_entity_id +
          "#" +
          step.template_id +
          "#" +
          step.key +
          (![undefined, null, ""].includes(step.filter)
            ? "@" + step.filter
            : "") +
          (step.divide_data_by ? "/" + step.divide_data_by : "")
        );
      }
      return step.key;
    },
    getDataTableData(data, add, key) {
      this.rowColors = {};
      let result = [],
        total = {},
        rules = (this.payload?.rules || []).filter((e) => {
          if (
            e?.actions &&
            e.actions.find((el) => el.operator_type == "CHANGE_COLOR")
          ) {
            return e;
          }
        });
      (data || []).forEach((rawRow, index) => {
        let row = JSON.parse(JSON.stringify(rawRow));
        let obj = {},
          form = {},
          values = [];
        if (!add) {
          obj["parentDataId"] = row._id;
        }
        (add
          ? (this.payload.main_columns || []).filter(
              (e) => e.is_hide_column !== true
            )
          : this.payload.main_columns || []
        ).forEach((step, i) => {
          if (key) {
            let value = this.getMappedDataRow(step, row, i);
            let checkField = this.getField(step);
            obj[step[key]] = value;
            form[step.key] = value;
            if (i == 0) {
              total[step[key]] = "Total";
            } else {
              if (
                this.isNumber(obj[step[key]]) &&
                step?.include_totals_in_export
              ) {
                if (total[step[key]]) {
                  total[step[key]] =
                    Number(obj[step[key]]) + Number(total[step[key]]);
                } else {
                  total[step[key]] = Number(obj[step[key]]);
                }
              } else {
                total[step[key]] = "  ";
              }
            }
            if (
              this.isNumber(obj[step[key]]) &&
              checkField &&
              ["AGGREGATE_FUNCTION", "CURRENCY", "FORMULA"].includes(
                checkField.inputType
              ) &&
              add
            ) {
              obj[step[key]] = parseFloat(obj[step[key]].toFixed(2));
              obj[step[key]] = this.getCurrencyFormat(
                obj[step[key]],
                checkField
              );
              if (index == data.length - 1) {
                total[step[key]] = this.getCurrencyFormat(
                  total[step[key]],
                  checkField
                );
              }
            }
            if (checkField?.inputType == "LIST" && obj[this.getProp(step)]) {
              let options = obj[this.getProp(step)]
                .split(",")
                .map((e) => e.trim());
              let val = [];
              (checkField.list_data || []).forEach((e) => {
                if (options.indexOf(e.value.trim()) != -1) {
                  val.push(e.name);
                }
              });
              obj[this.getProp(step)] = val.join(",");
            }
          } else {
            let value = this.getMappedDataRow(step, row, i);
            if (step?.divide_data_by && value.length) {
              values = value;
              value.forEach((v) => {
                let date = new Date(v[step.divide_data_by]);
                let month = date.getMonth() + 1;
                let key =
                  month +
                  "-" +
                  date.getDate() +
                  "-" +
                  date.toLocaleString("default", { weekday: "short" });
                obj[key] = v[step.key];
                form[key] = v[step.key];
                (step?.actionColumns || []).forEach((cl) => {
                  if (cl && v["action/" + cl]) {
                    obj["action/" + key + "/" + cl] = v["action/" + cl];
                    form["action/" + key + "/" + cl] = v["action/" + cl];
                  }
                });
              });
            } else {
              obj[this.getProp(step)] = value;
              form[step.key] = value;
            }
            if (i == 0) {
              total[this.getProp(step)] = "Total";
            } else {
              if (
                this.isNumber(obj[this.getProp(step)]) &&
                step?.include_totals_in_export
              ) {
                if (total[this.getProp(step)]) {
                  total[this.getProp(step)] =
                    Number(obj[this.getProp(step)]) +
                    Number(total[this.getProp(step)]);
                } else {
                  total[this.getProp(step)] = Number(obj[this.getProp(step)]);
                }
              } else {
                total[this.getProp(step)] = "  ";
              }
            }
            let checkField = this.getField(step);
            if (
              this.isNumber(obj[this.getProp(step)]) &&
              checkField &&
              ["AGGREGATE_FUNCTION", "CURRENCY", "FORMULA"].includes(
                checkField.inputType
              ) &&
              add
            ) {
              obj[this.getProp(step)] = parseFloat(
                obj[this.getProp(step)].toFixed(2)
              );
              obj[this.getProp(step)] = this.getCurrencyFormat(
                obj[this.getProp(step)],
                checkField
              );
              if (index == data.length - 1) {
                total[this.getProp(step)] = this.getCurrencyFormat(
                  total[this.getProp(step)],
                  checkField
                );
              }
            }
            if (
              checkField?.inputType == "LIST" &&
              obj[this.getProp(step)] &&
              typeof obj[this.getProp(step)] == "object"
            ) {
              let options = obj[this.getProp(step)]
                .split(",")
                .map((e) => e.trim());
              let val = [];
              (checkField.list_data || []).forEach((e) => {
                if (options.indexOf(e.value.trim()) != -1) {
                  val.push(e.name);
                }
              });
              obj[this.getProp(step)] = val.join(",");
            }
          }
        });
        if (rules && rules.length && !add) {
          let allSlots = [];
          rules.map((rule) => {
            rule.conditions.forEach((a) => {
              if (
                a.selectField == "compare_slots" &&
                this.checkIsDividedFilterAdded
              ) {
                if (form[a.start_date] && form[a.frequency]) {
                  let startDateKey = Object.keys(row).find(
                    (r) => r.split("#")[2] == a.start_date
                  );
                  let startFrequencyKey = Object.keys(row).find(
                    (r) => r.split("#")[2] == a.frequency
                  );
                  if (startDateKey && startFrequencyKey) {
                    (row[startDateKey] || []).forEach((stDate) => {
                      if (new Date(stDate[a.start_date]) < new Date()) {
                        let frequencyValue = row[startFrequencyKey].find(
                          (fr) => fr._id == stDate._id
                        );
                        let slots = this.generateSlots(
                          stDate[a.start_date],
                          frequencyValue[a.frequency],
                          form,
                          index
                        );
                        allSlots = [...allSlots, ...slots];
                      }
                    });
                  } else if (form[a.start_date]) {
                    obj["slotStartDateValue"] = form[a.start_date];
                    if (new Date(form[a.start_date]) < new Date()) {
                      let frequencyValue = 0;
                      Object.keys(row.entityData || {}).forEach((temp) => {
                        Object.keys(row.entityData[temp]).forEach((field) => {
                          if (field == a.frequency) {
                            frequencyValue = row.entityData[temp][field];
                          }
                        });
                      });
                      obj["slotFrequencyValue"] = frequencyValue;
                      let slots = this.generateSlots(
                        form[a.start_date],
                        frequencyValue,
                        form,
                        index
                      );
                      allSlots = [...allSlots, ...slots];
                    }
                  }
                }
              }
            });
          });
          (this.payload.main_columns || []).forEach((column) => {
            if (column?.divide_data_by) {
              this.getCurrentMonthDays.forEach((day) => {
                let key = day.month + "-" + day.date + "-" + day.dayName;
                let newRules = [];
                rules.forEach((rule) => {
                  let actions = [],
                    conditions = [];
                  rule.conditions.forEach((t) => {
                    let a = JSON.parse(JSON.stringify(t));
                    if (
                      a.selectField ==
                      column.key + "/" + column.divide_data_by
                    ) {
                      a.selectField = key;
                    }
                    conditions.push(a);
                  });
                  rule.actions.forEach((t) => {
                    let a = JSON.parse(JSON.stringify(t));
                    if (
                      a.selectField ==
                      column.key + "/" + column.divide_data_by
                    ) {
                      a.selectField = key;
                    }
                    actions.push(a);
                  });
                  newRules.push({
                    ...rule,
                    actions: actions,
                    conditions: conditions,
                  });
                });
                let currentColumnDate =
                  day.month + "-" + day.date + "-" + day.dayName;
                let [m, d] = currentColumnDate.split("-");
                let checkDate = new Date(
                  new Date(this.currentDate).getFullYear(),
                  m - 1,
                  d
                );
                let found = allSlots.find((f) => {
                  if (
                    checkDate >= new Date(f.start) &&
                    checkDate < new Date(f.end) &&
                    f.found
                  ) {
                    return true;
                  }
                });
                obj[currentColumnDate + "@data"] = found
                  ? values
                      .filter((f) => {
                        let checkDate = new Date(f[column.divide_data_by]);
                        if (
                          checkDate >= new Date(found.start) &&
                          checkDate < new Date(found.end)
                        ) {
                          return true;
                        }
                      })
                      .map((f) => f._id)
                  : [];

                let { style, rulesResults } = this.checkEntityDataConditions(
                  key,
                  newRules,
                  {
                    ...form,
                    ...{
                      currentColumnDate,
                      allSlots: allSlots,
                      row_data: rawRow,
                    },
                  },
                  this.payload.main_columns,
                  true
                );
                if (style) {
                  this.setStyles(key, style, obj);
                }
                if (rulesResults) {
                  obj[key + "$rulesResults"] = rulesResults;
                }
              });
            } else {
              let style = this.checkEntityDataConditions(
                column.key,
                rules,
                form,
                this.payload.main_columns
              );
              if (style) {
                this.setStyles(column.key, style, obj);
              }
            }
          });
        }
        result.push(obj);
      });
      if (add) {
        result.push(total);
      }
      return result;
    },
    generateSlots(startDate, frequency, form) {
      const slots = [];
      let currentDate = new Date();
      let start = new Date(startDate);
      frequency = parseInt(frequency);

      if (frequency === 7) {
        start.setDate(start.getDate() - start.getDay());
      } else if (frequency === 30 || frequency === 31) {
        start.setDate(1);
      } else if (frequency === 15) {
        if (start.getDate() >= 15) {
          start.setDate(15);
        } else {
          start.setDate(1);
        }
      }
      while (start < currentDate) {
        let end = new Date(start);
        end.setDate(end.getDate() + frequency);
        if (end > currentDate) {
          end = currentDate;
        }
        let found = false;
        Object.keys(form).forEach((f) => {
          if (f.includes("-") && f.split("-").length == 3 && form[f]) {
            let [month, date] = f.split("-");
            let checkDate = new Date(
              new Date(start).getFullYear(),
              month - 1,
              date
            );
            if (checkDate >= start && checkDate < end) {
              found = true;
            }
          }
        });
        slots.push({
          start,
          end,
          found,
        });
        start = end;
      }
      return slots;
    },
    setStyles(key, style, obj) {
      if (this.rowColors[obj.parentDataId]) {
        if (this.rowColors[obj.parentDataId][key]) {
          this.rowColors[obj.parentDataId][key].push(style);
        } else {
          this.rowColors[obj.parentDataId] = {
            ...this.rowColors[obj.parentDataId],
            ...{
              [key]: [style],
            },
          };
        }
      } else {
        this.$set(this.rowColors, obj.parentDataId, {
          [key]: [style],
        });
      }
    },
    getMappedDataRow(step, data, index) {
      if (step.main_entity_field && step.field_type == "DISPLAY") {
        return this.getFieldValue(data, step);
      } else if (step.entity_id && step.field_type == "FILTER") {
        if (
          this.appliedFilters &&
          this.appliedFilters[step.template_id + "#" + step.key] &&
          (this.allEntityDataList(step.entity_id) || []) &&
          (this.allEntityDataList(step.entity_id) || []).find(
            (e) =>
              e._id == this.appliedFilters[step.template_id + "#" + step.key]
          )
        ) {
          let label = this.getLabel(
            (this.allEntityDataList(step.entity_id) || []).find(
              (e) =>
                e._id == this.appliedFilters[step.template_id + "#" + step.key]
            ),
            index + 1,
            step.entity_id
          );
          this.appliedFiltersNames[step.template_id + "#" + step.key] = label;
          return label;
        } else {
          this.appliedFiltersNames[step.template_id + "#" + step.key] = "All";
          return "All";
        }
      } else if (!step.main_entity_field && step.linked_entity_id) {
        if (
          step.field_type == "SUM" ||
          step.field_type == "AVG" ||
          step.field_type == "COUNT"
        ) {
          let value = this.getOtherEntityFieldValue(data, step, step.key);
          if (step.divide_data_by) {
            return value;
          }
          return this.formatNumber(value);
        } else if (step.field_type == "DISPLAY") {
          if (
            this.getOtherEntityFieldValue(data, step, step.key) &&
            typeof this.getOtherEntityFieldValue(data, step, step.key) ==
              "object" &&
            this.getOtherEntityFieldValue(data, step, step.key).length == 1
          ) {
            return this.checkIsDateField(
              this.getOtherEntityFieldValue(data, step, step.key)[0][step.key]
            );
          } else if (
            this.getOtherEntityFieldValue(data, step, step.key) &&
            typeof this.getOtherEntityFieldValue(data, step, step.key) ==
              "object" &&
            this.getOtherEntityFieldValue(data, step, step.key).length > 1
          ) {
            if (step?.allow_view_details && step?.detailed_entity_view_id) {
              return "MULTIPLE";
            } else {
              return this.getOtherEntityFieldValue(data, step, step.key)
                .map((dt) => this.checkIsDateField(dt[step.key]))
                .filter((dt) => dt && dt != "" && dt != null)
                .join(", ");
            }
          } else {
            return step.default_value ? step.default_value : "";
          }
        } else if (step.field_type == "AGGREGATION_SUM") {
          if (this.getOtherEntityFieldValue(data, step, step.data_table_key)) {
            return this.sumOfArray(data, step, step.data_table_key);
          } else {
            return 0;
          }
        }
      } else if (step.field_type == "FORMULA") {
        return this.getFormulaValue(data, step);
      }
    },
    formatNumber(value) {
      if (!isNaN(value)) {
        if (typeof value === "string") {
          value = parseFloat(value);
        }
        return parseFloat(value.toFixed(2));
      } else {
        return value;
      }
    },
    arrayConstructor(l, startIndex, elements) {
      let array = new Array(l).fill("");
      elements.forEach((e) => {
        array[startIndex] = e;
        startIndex++;
      });
      return array;
    },
    getFilterHeaders(fromSheet) {
      const format = this.getDefaultDateFormat
        ? this.getDefaultDateFormat
        : "YYYY-MM-DD";
      let filters = [];
      if (this.payload.include_filters_in_export) {
        const selectedColumns = this.selectedColumnKeys.length
          ? (this.payload.main_columns || [])
              .filter((column) => {
                let key =
                  column.key +
                  (![undefined, null, ""].includes(column.filter)
                    ? `@${column.flter}`
                    : "");
                if (this.selectedColumnKeys.includes(key) && column.label) {
                  return true;
                }
                return false;
              })
              .map((column) => column.label)
          : (this.payload.main_columns || []).map((column) => column.label);
        let startIndex = selectedColumns.length / 2 - 1;

        if (fromSheet) {
          filters.push(
            this.arrayConstructor(selectedColumns.length, startIndex, [
              this.payload.name,
            ])
          );
        } else {
          filters.push([this.payload.name]);
        }
        (this.payload.filter_fields || []).forEach((filter) => {
          let filterData = [];
          if (
            this.selectedFileds[filter.key] &&
            this.selectedFileds[filter.key] !== "all"
          ) {
            let value = this.selectedFileds[filter.key];
            if (filter.key == "currentEntity") {
              let label;
              if (typeof value == "object" && value.length) {
                label = value
                  .map((el) => {
                    return this.getLabel(
                      (this.allEntityDataList(filter.entity_id) || []).find(
                        (e) => el == e._id
                      ),
                      1,
                      filter.entity_id
                    );
                  })
                  .join(",");
              } else {
                label = this.getLabel(
                  (this.allEntityDataList(filter.entity_id) || []).find(
                    (e) => value == e._id
                  ),
                  1,
                  filter.entity_id
                );
              }
              filterData.push(filter.label);
              filterData.push(label);
              if (fromSheet) {
                filters.push(
                  this.arrayConstructor(
                    selectedColumns.length,
                    startIndex - 1,
                    filterData
                  )
                );
              } else {
                filters.push(filterData);
              }
            } else if (filter.type == "ENTITY" || filter.type == "RELATION") {
              let label = "";
              if (typeof value == "object" && value.length) {
                label = value
                  .map((el) => {
                    return this.getLabel(
                      (this.allEntityDataList(filter.entity_id) || []).find(
                        (e) => el == e._id
                      ),
                      1,
                      filter.entity_id
                    );
                  })
                  .join(",");
              } else {
                label = this.getLabel(
                  (this.allEntityDataList(filter.entity_id) || []).find(
                    (e) => value == e._id
                  ),
                  1,
                  filter.entity_id
                );
              }
              filterData.push(filter.label);
              filterData.push(label);
              if (fromSheet) {
                filters.push(
                  this.arrayConstructor(
                    selectedColumns.length,
                    startIndex - 1,
                    filterData
                  )
                );
              } else {
                filters.push(filterData);
              }
            } else if (filter.type == "DATE") {
              let label;
              if (filter.filter_type == "DATE_RANGE") {
                label =
                  moment(value && value[0] ? value[0] : value).format(format) +
                  " - " +
                  moment(value && value[1] ? value[1] : value).format(format);
              } else if (filter.filter_type == "PICKER_OPTIONS") {
                label = this.pickerOptions.find(
                  (po) => po.value == value
                ).label;
              }
              filterData.push(filter.label);
              filterData.push(label);
              if (fromSheet) {
                filters.push(
                  this.arrayConstructor(
                    selectedColumns.length,
                    startIndex - 1,
                    filterData
                  )
                );
              } else {
                filters.push(filterData);
              }
            } else if (filter.type == "SELECT" && value) {
              let label = "";
              if (value && typeof value == "object") {
                label = value.join(",");
              } else {
                label = value;
              }
              filterData.push(filter.label);
              filterData.push(label);
              if (fromSheet) {
                filters.push(
                  this.arrayConstructor(
                    selectedColumns.length,
                    startIndex - 1,
                    filterData
                  )
                );
              } else {
                filters.push(filterData);
              }
            }
          }
        });
        if (filters.length) {
          filters.push([]);
        }
      }
      return filters;
    },
    async exportToExcel() {
      try {
        if (this.downloadingFile) {
          return;
        }
        if (!this.selectedColumnKeys.length) {
          this.selectedColumnKeys = this.payload.main_columns.map(
            (column) =>
              column.key +
              (![undefined, null, ""].includes(column.filter)
                ? `@${column.filter}`
                : "")
          );
        }
        this.downloadingFile = true;
        this.$message("Please wait, generating your report...");
        let [mainFilters, currentEntityDataids] = this.getMainFilters();
        await postAPICall(
          "POST",
          "/entities-data/entity/view/data-export",
          {
            ...{
              appliedFiltersNames: this.appliedFiltersNames,
              main_filters: this.mapAllSelectedFilters(mainFilters),
              entity_data_ids: currentEntityDataids,
              getFilterHeaders: this.getFilterHeaders(false),
            },
            ...this.payload,
            columns: this.selectedColumnKeys,
          },
          false,
          "blob"
        ).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${this.payload.name ? this.payload.name : "data"}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          this.downloadingFile = false;
        });
      } catch (e) {
        this.$message.error(
          "Oops, Error while generating report. Please try again."
        );
        this.downloadingFile = false;
      }
      // let dataArray = this.getDataTableData(
      //   [...Object.values(this.entityViewData).flatMap((dt) => dt)],
      //   true
      // );
      // const keys = Array.from(
      //   new Set(dataArray.flatMap((obj) => Object.keys(obj)))
      // );
      // const labels = (this.payload.main_columns || [])
      //   .filter((e) => e.is_hide_column !== true)
      //   .flatMap((e) => e.label);
      // let filters = this.getFilterHeaders(true);
      // const sheetData = [
      //   ...filters,
      //   labels,
      //   ...dataArray.map((item) => keys.map((key) => item[key] || "")),
      // ];
      // const ws = XLSX.utils.aoa_to_sheet(sheetData);
      // ws["!cols"] = fitToColumn(sheetData);
      // function fitToColumn(arrayOfArray) {
      //   return arrayOfArray[filters.length + 2].map((a, i) => ({
      //     wch: Math.max(
      //       ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length : 0))
      //     ),
      //   }));
      // }
      // const wb = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // XLSX.writeFile(
      //   wb,
      //   (this.payload.name ? this.payload.name : "data") + ".xlsx"
      // );
    },
    async exportToPDF(sendAttchment = false) {
      this.loading = true;
      this.loadingText = "Generating PDF";
      if (this.primaryFilter) {
        if (!this.payload.printing_template) {
          this.$notify.warning({
            title: "Warning",
            message: "Can't download. Header template is not configured",
          });
          this.loading = false;
          return;
        }
        this.$refs.html2Pdf.generatePdf();
      } else {
        if (!this.payload.printing_document) {
          this.$notify.warning({
            title: "Warning",
            message: "Can't download. Document template is not configured",
          });
          this.loading = false;
          return;
        }
        let primaryFilterData = {},
          filters = {};
        this.getFilterHeaders(false).map((flt) => {
          if (flt.length > 1) {
            filters = { ...filters, ...{ [flt[0]]: flt[1] } };
          }
        });
        if (
          this.payload.filter_fields.filter((fd) => fd.primary_filter).length
        ) {
          this.selectedFileds[
            this.payload.filter_fields.find((fd) => fd.primary_filter).key
          ].map((dt) => {
            primaryFilterData[dt] = this.getPrimaryFilterLabel(dt);
          });
        }
        let [mainFilters, currentEntityDataids] = this.getMainFilters();
        let params = {
          filters: filters,
          aggregations: Object.values(this.aggregationValues).flatMap(
            (dt) => dt
          )[0],
          aggregationFields: this.payload.aggregations.flatMap(
            (ag) => ag.label
          ),
          appliedFiltersNames: this.appliedFiltersNames,
          main_filters: this.mapAllSelectedFilters(mainFilters),
          entity_data_ids: currentEntityDataids,
          getFilterHeaders: this.getFilterHeaders(false),
          ...this.payload,
        };
        // if (sendAttchment) {
        //   let sendField = (this.payload.filter_fields || []).find(
        //     (e) => e.key == this.payload?.send_attachment_to
        //   );
        //   params = {
        //     ...params,
        //     ...{
        //       email_template_id: this.payload.attachment_email_template,
        //       send_attachment_to:
        //         this.selectedFileds?.[this.payload.send_attachment_to],
        //       sendField: sendField,
        //     },
        //   };
        // }
        await this.$store.dispatch("entities/entityViewsExport", params);
        if (
          this.getEntityViewsPDFExport &&
          this.getEntityViewsPDFExport?.success &&
          this.getEntityViewsPDFExport?.data?.length
        ) {
          if (sendAttchment) {
            this.loading = false;
            return this.getEntityViewsPDFExport?.data;
          } else {
            await this.downloadEntityDataPdf(
              this.getEntityViewsPDFExport.data[0],
              this.payload?.name
            );
            this.loading = false;
            return null;
          }
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error while generating the document",
          });
          this.loading = false;
          return null;
        }
      }
      this.loading = false;
      this.loadingText = "Fetching data...";
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        let actualStep = this.removeHiddenColumns(
          this.payload.main_columns || []
        ).find((e) => this.getProp(e) == column.property);
        if (index === 0) {
          sums[index] = "Total";
          return;
        }
        const values = data.map((item) => {
          if (item[column.property]) {
            return Number(item[column.property]);
          }
          let [entity, template_id, key] = column.property.split("#");
          if (
            entity &&
            item?.entityData?.[template_id]?.[key] &&
            Number(item.entityData[template_id][key])
          ) {
            return Number(item.entityData[template_id][key]);
          }
          if (column.property) {
            let field = this.payload.main_columns.find(
              (e) =>
                (e.key == column.property && e.field_type == "FORMULA") ||
                e.linked_entity_id + "#" + e.template_id + "#" + e.key ==
                  column.property
            );
            if (field?.field_type == "FORMULA") {
              return Number(this.getFormulaValue(item, field));
            } else if (field?.field_type == "AGGREGATION_SUM") {
              return Number(
                this.sumOfArray(item, field, field.data_table_key) || []
              );
            }
          }
        });
        if (
          !values.every((value) => isNaN(value)) &&
          actualStep?.include_total
        ) {
          let calculatedValue = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          let checkField = this.getField(actualStep);
          let roundedValue = parseFloat(calculatedValue.toFixed(2));
          if (
            checkField &&
            ["AGGREGATE_FUNCTION", "CURRENCY", "FORMULA"].includes(
              checkField.inputType
            )
          ) {
            roundedValue = this.getCurrencyFormat(roundedValue, checkField);
          }
          sums[index] = roundedValue;
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
    isNumber(value) {
      if (typeof value === "number" && !isNaN(value)) {
        return true;
      }
      if (typeof value === "string") {
        return /^[+-]?\d+(\.\d+)?$/.test(value) && !isNaN(parseFloat(value));
      }
      return false;
    },
    checkDate(string) {
      let dateStamp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
      if (dateStamp.test(string)) {
        return true;
      }
      return false;
    },
    isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    },
    generateKeyForField(label) {
      let parsedLabel = label.replace(/[^a-zA-Z 0-9]/g, "");
      parsedLabel = parsedLabel.toLowerCase().split(" ").join("_");
      return parsedLabel;
    },
    getField(step) {
      if (step.field_type != "FORMULA") {
        if (step.main_entity_field) {
          return this.selectedEntityFields.find(
            (e) =>
              e.key && e.key.split("#")[1] && e.key.split("#")[1] == step.key
          );
        } else {
          return (
            this.otherEntitiesAllFields?.[step.linked_entity_id] || []
          ).find((e) => e?.key == step.template_id + "#" + step.key);
        }
      }
    },
    getFieldValue(row, step) {
      if (
        ["created_at", "updated_at", "created_by", "updated_by"].includes(
          step.key
        )
      ) {
        return row?.[step.key] || "";
      }
      let checkIsGlobalvariable = this.selectedEntityFields.find(
        (e) => e.key && e.key.split("#")[1] && e.key.split("#")[1] == step.key
      );
      if (checkIsGlobalvariable) {
        if (
          checkIsGlobalvariable.is_global_variable &&
          checkIsGlobalvariable.global_variable_id
        ) {
          let globalVariable = (this.globalVariablesData || []).find(
            (e) => e._id == checkIsGlobalvariable.global_variable_id
          );
          if (globalVariable) {
            if (
              globalVariable.input_type == "IMAGE" &&
              globalVariable.image_url
            ) {
              return globalVariable.image_url;
            } else if (globalVariable.value) return globalVariable.value;
          }
        }
        step["type"] = checkIsGlobalvariable.inputType;
      }
      if (
        step.type == "DATE" &&
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        row.entityData[step.template_id][step.key]
      ) {
        // const format = this.getDefaultDateFormat
        //   ? this.getDefaultDateFormat
        //   : "MM-DD-YYYY";
        // return moment(row.entityData[step.template_id][step.key]).format(
        //   format
        // );
        return row.entityData[step.template_id][step.key];
      } else if (step.type == "WEEKDAYS") {
        if (
          row.entityData &&
          step.template_id &&
          row.entityData[step.template_id] &&
          row.entityData[step.template_id][step.key]
        ) {
          if (
            checkIsGlobalvariable?.allow_multiple &&
            typeof row.entityData[step.template_id][step.key] == "object" &&
            row.entityData[step.template_id][step.key].length
          ) {
            let mappedArray = row.entityData[step.template_id][step.key].map(
              (e) => this.weekDayMap[e]
            );
            return mappedArray.join(",");
          } else {
            return this.weekDayMap[row.entityData[step.template_id][step.key]];
          }
        }
        return "";
      } else if (step.type == "HTML_CONTENT") {
        let newfd = this.currentEntity.templates.find((el) => {
          if (el.template_id === step.template_id) {
            return el.templateInfo;
          }
        });
        let val = newfd.templateInfo.sections[0].fields.find((el) => {
          let keyVal = el.key.split("#")[1];
          if (keyVal === step.key && el.input_type == "HTML_CONTENT") {
            return el;
          }
        });

        return val.content;
        // return row.entityData[step.template_id][step.key]
      } else if (step.type == "IMAGE") {
        let id = this.generateKeyForField(step.label);
        if (
          row.entityData &&
          step.template_id &&
          row.entityData[step.template_id] &&
          row.entityData[step.template_id][id]
        ) {
          return row.entityData[step.template_id][id];
        }
      } else if (
        step.type == "FILE" &&
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        row.entityData[step.template_id][step.key]
      ) {
        this.documentsData =
          row.entityData[step.template_id][step.key].files &&
          row.entityData[step.template_id][step.key].files.length
            ? row.entityData[step.template_id][step.key].files
            : row.entityData[step.template_id][step.key];
        this.documentSearchData = this.documentsData;
        return row.entityData[step.template_id][step.key].files &&
          row.entityData[step.template_id][step.key].files.length
          ? row.entityData[step.template_id][step.key].files
          : row.entityData[step.template_id][step.key];
      } else if (
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        row.entityData[step.template_id][step.key]
      ) {
        if (
          typeof row.entityData[step.template_id][step.key] == "number" &&
          this.isFloat(row.entityData[step.template_id][step.key])
        ) {
          return parseFloat(row.entityData[step.template_id][step.key]).toFixed(
            2
          );
        }
        if (step.type == "MULTI_SELECT") {
          let value = row.entityData[step.template_id][step.key]
            ? row.entityData[step.template_id][step.key]
            : "";
          if (value) {
            if (typeof value == "object" && value.length) {
              return value.join(",");
            }
            return value;
          }
          return "";
        } else if (
          step.type == "SELECT" &&
          row.entityData[step.template_id][step.key]
        ) {
          let result,
            field = this.selectedEntityFields.find(
              (e) => e.key === step.template_id + "#" + step.key
            );
          if (
            field &&
            field.inputType == "SELECT" &&
            field.is_list &&
            field.global_variable_id
          ) {
            let value = field.options.find(
              (e) => e.value == row.entityData[step.template_id][step.key]
            );
            if (value && value.name) {
              result = value.name;
            }
            result = row.entityData[step.template_id][step.key];
          } else {
            result = row.entityData[step.template_id][step.key];
          }
          if (result && typeof result == "object" && result.length) {
            result = result[0];
          }
          return result;
        } else if (
          step.type == "LIST" &&
          row.entityData[step.template_id][step.key]
        ) {
          let result,
            field = this.selectedEntityFields.find(
              (e) => e.key === step.template_id + "#" + step.key
            );
          if (field?.list_data) {
            let value = field.list_data.find(
              (e) =>
                e.value == row.entityData[step.template_id][step.key] &&
                (e.name ==
                  row.entityData[step.template_id][step.id + "_info"] ||
                  !row.entityData[step.template_id][step.id + "_info"])
            );
            if (value && value.name) {
              result = value.name;
            } else {
              result = row.entityData[step.template_id][step.key];
            }
          } else {
            result = row.entityData[step.template_id][step.key];
          }
          if (result && typeof result == "object" && result.length) {
            result = result[0];
          }
          return result;
        } else if (
          step.type == "NUMBER" &&
          row.entityData[step.template_id][step.key]
        ) {
          return typeof row.entityData[step.template_id][step.key] == "number"
            ? row.entityData[step.template_id][step.key]
            : "";
        } else if (step.type == "CHECKBOX_GROUP") {
          if (
            row.entityData &&
            step.template_id &&
            row.entityData[step.template_id] &&
            row.entityData[step.template_id][step.key] &&
            typeof row.entityData[step.template_id][step.key] == "object" &&
            row.entityData[step.template_id][step.key].length
          ) {
            return row.entityData[step.template_id][step.key].length
              ? row.entityData[step.template_id][step.key].join(",")
              : "";
          } else {
            return row.entityData[step.template_id][step.key].length
              ? row.entityData[step.template_id][step.key]
              : "-";
          }
        } else if (
          step.type == "TIME_RANGE" &&
          row.entityData[step.template_id][step.key].length
        ) {
          if (row.entityData[step.template_id][step.key].length) {
            return (
              this.$moment(
                row.entityData[step.template_id][step.key][0]
              ).format("HH:mm:ss") +
              " - " +
              this.$moment(
                row.entityData[step.template_id][step.key][1]
              ).format("HH:mm:ss")
            );
          }
        } else if (
          step.type == "DATE_TIME_RANGE" &&
          row.entityData[step.template_id][step.key].length
        ) {
          if (row.entityData[step.template_id][step.key].length) {
            return (
              this.$moment(
                row.entityData[step.template_id][step.key][0]
              ).format("MM-DD-YYYY hh:mm:ss A") +
              " To " +
              this.$moment(
                row.entityData[step.template_id][step.key][1]
              ).format("MM-DD-YYYY hh:mm:ss A")
            );
          }
        } else if (
          step.type == "DATE_RANGE" &&
          row.entityData[step.template_id][step.key].length
        ) {
          if (row.entityData[step.template_id][step.key].length) {
            return (
              this.$moment(
                row.entityData[step.template_id][step.key][0]
              ).format("MM-DD-YYYY") +
              " To " +
              this.$moment(
                row.entityData[step.template_id][step.key][1]
              ).format("MM-DD-YYYY")
            );
          }
        }
        if (row.entityData[step.template_id][step.key + "/name"]) {
          if (
            typeof row.entityData[step.template_id][step.key + "/name"] ==
              "object" &&
            row.entityData[step.template_id][step.key + "/name"].length
          ) {
            return row.entityData[step.template_id][step.key + "/name"].join(
              ","
            );
          } else if (
            this.checkDate(row.entityData[step.template_id][step.key + "/name"])
          ) {
            const format = this.getDefaultDateFormat
              ? this.getDefaultDateFormat
              : "MM-DD-YYYY";
            return moment(
              row.entityData[step.template_id][step.key + "/name"]
            ).format(format);
          }
          return row.entityData[step.template_id][step.key + "/name"];
        } else {
          let value = row.entityData[step.template_id][step.key]
            ? row.entityData[step.template_id][step.key]
            : "";
          if (value) {
            if (typeof value == "object" && value.length) {
              return value.map((e) => this.checkIsDateField(e)).join(",");
            }
            return value;
          }
          return "";
        }
      } else if (
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        typeof row.entityData[step.template_id][step.key] == "number"
      ) {
        return row.entityData[step.template_id][step.key];
      }
      return "";
    },
    allEntityDataList(entity_id) {
      if (
        entity_id &&
        this.entityDataMapping &&
        this.entityDataMapping[entity_id] &&
        this.entityDataMapping[entity_id].data
      ) {
        return this.entityDataMapping[entity_id].data;
      }
      return [];
    },
    getLabel(item, i, entity_id) {
      if (
        entity_id &&
        this.entityDataMapping &&
        this.entityDataMapping[entity_id] &&
        this.entityDataMapping[entity_id].selectedEntity
      ) {
        let selectedEntity = this.entityDataMapping[entity_id].selectedEntity;
        let name = "data " + i;
        // let name =
        //   item &&
        //   item.entity_prime_data &&
        //   Object.keys(item.entity_prime_data) &&
        //   item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
        //     ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
        //     : "data " + i;
        if (selectedEntity && selectedEntity.primaryFields) {
          let primaryField = selectedEntity.primaryFields[0];
          if (
            item?.entityData?.[primaryField?.template_id]?.[primaryField?.key]
          ) {
            name =
              primaryField.inputType == "ENTITY" ||
              primaryField.inputType === "INTEGRATION"
                ? item.entityData[primaryField.template_id][
                    primaryField.key + "/name"
                  ]
                : primaryField.input_type == "DATE"
                ? moment(
                    item.entityData[primaryField.template_id][primaryField.key]
                  ).format(this.getDefaultDateFormat)
                : primaryField.input_type == "DATE_TIME"
                ? moment(
                    item.entityData[primaryField.template_id][primaryField.key]
                  ).format(this.getDefaultDateFormat + " HH:mm:ss")
                : item.entityData[primaryField.template_id][primaryField.key];
          }
        }
        return name;
      }
      return "Data " + i;
    },
    reset() {
      this.payload.filter_fields.forEach((fl) => {
        if (["ENTITY", "SELECT", "RELATION"].includes(fl.type)) {
          if (fl.required) {
            this.$set(this.selectedFileds, fl.key, fl.multiple ? [] : "");
          } else {
            this.$set(
              this.selectedFileds,
              fl.key,
              fl.multiple ? ["all"] : "all"
            );
          }
          if (
            this.isApplicationUserSide &&
            ["ENTITY", "RELATION"].includes(fl.type) &&
            fl.isApplicationUser
          ) {
            if (fl.entity_id == this.getActiveContactType.contact_type._id) {
              this.$set(
                this.selectedFileds,
                fl.key,
                fl.multiple
                  ? [this.getActiveContactType.account_data_id]
                  : this.getActiveContactType.account_data_id
              );
            }
          }
        } else if (fl.type == "DATE") {
          if (this.checkIsDateSelected(fl)) {
            this.$set(this.selectedFileds, fl.key, this.currentDate);
          } else {
            let data = this.getDatePickerHelper(this.default_date);
            if (fl.filter_type == "DATE_RANGE") {
              this.$set(this.selectedFileds, fl.key, [data.value, data.today]);
            } else {
              this.$set(this.selectedFileds, fl.key, this.default_date);
            }
          }
        }
      });
    },
    resetFilters() {
      this.reset();
      if (this.checkGlobalFilter?.entity_id && this.getGlobalFiltervalue) {
        this.mapGlobalFilters();
        this.checkAndApplyFilters();
      } else {
        let [mainFilters, currentEntityDataids] = this.getMainFilters();
        this.fetchEntitiesData(mainFilters, currentEntityDataids);
      }
    },
    applyFilters() {
      this.loading = true;
      this.loadingText = "Fetching data...";
      this.appliedFilters = {};
      this.appliedFiltersNames = {};
      let empty = this.payload.filter_fields.find((fl) => {
        if (
          fl.required &&
          (!this.selectedFileds[fl.key] || !this.selectedFileds[fl.key].length)
        ) {
          return true;
        }
      });
      if (empty?.label) {
        this.$message({
          message: "Warning, Please select " + empty.label,
          type: "warning",
        });
        this.loading = false;
        return;
      }
      this.checkAndApplyFilters();
    },
    dateFilterCount(type) {
      return (this.payload.filter_fields || []).filter(
        (e) => e.type == "DATE" && e.filter_type == type
      ).length;
    },
    checkPickerOptionOnlySelected() {
      let isPickerSelected = false;
      let rangeCount = this.dateFilterCount("DATE_RANGE");
      let pickerCount = this.dateFilterCount("PICKER_OPTIONS");
      if (pickerCount && !rangeCount) {
        isPickerSelected = true;
      }
      return isPickerSelected;
    },
    getMonthStartAndEnd(date) {
      const year = date.getFullYear();
      const month = date.getMonth();
      const startDate = new Date(year, month, 1).toISOString();
      const endDate = new Date(year, month + 1, 0).toISOString();
      return [startDate, endDate];
    },
    getMainFilters() {
      let mainFilters = [],
        currentEntityDataids = [];
      let isPickerSelected = this.checkPickerOptionOnlySelected();
      this.payload.filter_fields.forEach((el) => {
        let value = this.selectedFileds[el.key];
        this.$set(this.appliedFilters, el.key, value);
        if (this.checkIsDateSelected(el)) {
          this.currentDate = new Date(value);
          value = this.getMonthStartAndEnd(new Date(value));
        }
        if (el.key == "currentEntity") {
          if (value && value.indexOf("all") === -1) {
            currentEntityDataids = [
              ...currentEntityDataids,
              ...(typeof value == "object" ? value : [value]),
            ];
          }
        } else if (el.type == "RELATION" && value) {
          mainFilters.push({
            data_source: "self_field",
            data_type: "RELATION",
            field: el.key.split("@")[1].includes("-")
              ? "nested#related_to/"
              : "self#related_to",
            operator: "in",
            query_type: "AND",
            relation_entity_id: el.key.split("@")[0],
            nested_related_id: el.key.split("@")[1].includes("-")
              ? el.key.split("@")[1].split("-")[1]
              : "",
            main_entity_field: true,
            value: typeof value == "object" ? value : [value],
          });
        } else if (el.type == "ENTITY") {
          let filter = {
            field: el.key,
            operator: "in",
            value: typeof value == "object" ? value : [value],
            value_type: "",
            value_source: "",
            data_type: "OBJECT_ID",
            query_type: "AND",
            value_field: "",
            validations: {
              data_type: "",
            },
            relation_entity_id: "",
            data_source: "ENTITY_DATA_LIST",
            main_entity_field: el.main_entity_field,
            linked_entity_ids: el.linked_entity_ids,
          };
          mainFilters.push(filter);
        } else if (el.type == "DATE" && el.filter_type == "DATE_RANGE") {
          let filter = {
            field: el.key,
            operator: "between",
            value: [
              value && value[0] ? value[0] : value,
              value && value[1] ? value[1] : value,
            ],
            value_type: "",
            value_source: "",
            data_type: "DATE",
            query_type: "AND",
            value_field: "",
            validations: {
              data_type: "",
            },
            relation_entity_id: "",
            main_entity_field: el.main_entity_field,
            linked_entity_ids: el.linked_entity_ids,
          };
          mainFilters.push(filter);
        } else if (el.type == "SELECT" && value) {
          let filter = {
            field: el.key,
            operator: "in",
            value: typeof value == "object" ? value : [value],
            value_type: "",
            value_source: "",
            data_type: "ARRAY",
            query_type: "AND",
            value_field: "",
            validations: {
              data_type: "",
            },
            relation_entity_id: "",
            data_source: "ENTITY_DATA_LIST",
            main_entity_field: el.main_entity_field,
            linked_entity_ids: el.linked_entity_ids,
          };
          mainFilters.push(filter);
        } else if (
          isPickerSelected &&
          el.type == "DATE" &&
          el.filter_type == "PICKER_OPTIONS"
        ) {
          let dateValueObj = this.getDatePickerHelper1(value);
          let dateValue1 = dateValueObj.value;
          let filter = {
            field: el.key.replace("/picker", ""),
            operator: "between",
            value: [
              dateValue1 && dateValue1[0] ? dateValue1[0] : dateValue1,
              dateValue1 && dateValue1[1] ? dateValue1[1] : dateValue1,
            ],
            value_type: "",
            value_source: "",
            data_type: "DATE",
            query_type: "AND",
            value_field: "",
            validations: {
              data_type: "",
            },
            relation_entity_id: "",
            main_entity_field: el.main_entity_field,
            linked_entity_ids: el.linked_entity_ids,
          };
          mainFilters.push(filter);
        }
      });
      return [mainFilters, currentEntityDataids];
    },
    checkAndApplyFilters() {
      this.loading = true;
      let [mainFilters, currentEntityDataids] = this.getMainFilters();
      this.fetchEntitiesData(mainFilters, currentEntityDataids);
    },
    mapTimeValue(date, time) {
      if (date && date.includes("T")) {
        let newData = date.split("T")[0];
        return newData + "T" + time;
      }
      return date + "T" + time;
    },
    openFiltersCollapse() {
      this.isFilterOpen = !this.isFilterOpen;
    },
    async fetchDependentEnityData() {
      if (this.dependentFilters) {
        await Promise.all(
          this.dependentFilters.map(async (el) => {
            if (el.filters && el.filters.length) {
              this.loadingFilters[el.key] = true;
              let filters = el.filters.map((fl) => {
                if (fl.data_source && fl.value_field) {
                  fl.value = this.selectedFileds[fl.value_field];
                }
                return fl;
              });
              return await this.fetchEntityData(el.entity_id, filters, el.key);
            }
            return el;
          })
        );
        this.refresh = true;
        await setTimeout(() => {
          this.refresh = false;
        });
      }
    },
    removeAllFilters(filters) {
      return filters.filter((filter) => {
        if (filter?.data_source == "self_field") {
          if (
            filter?.value &&
            filter.value.length &&
            filter.value.includes("all")
          ) {
            return false;
          }
        }
        return true;
      });
    },
    async fetchEntityData(el, filters = [], key = "") {
      let params = {
        entity_id: el,
        template_fields_data: [],
        filters: this.removeAllFilters(filters),
        relationship: null,
        limit: 500,
        page: 1,
      };
      // await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      let responseData = await postAPICall(
        "POST",
        "/entities-data/entity/data",
        params
      );
      if (responseData && responseData.data) {
        this.entityDataMapping[el] = {
          ...responseData,
          ...{ update: true },
        };
        this.otherEntities[el] = responseData.selectedEntity;
        this.otherEntitiesAllFields[el] = [
          ...this.getAllFieldsFromEntity(
            responseData.selectedEntity,
            false,
            false,
            true,
            true
          ),
        ];
      }
      if (key) {
        this.$set(this.loadingFilters, key, false);
      }
      return el;
    },
    async fetchFilterEntityData() {
      let entityFields = (this.payload.filter_fields || [])
        .filter(
          (e) =>
            e.type == "ENTITY" &&
            e.entity_id &&
            (e.key != "currentEntity" ||
              (e.key == "currentEntity" &&
                (!e.filters ||
                  !e.filters.length ||
                  !e.filters.find((f) => f.field == "self#related_to/1"))))
        )
        .flatMap((e) => e.entity_id);
      entityFields = [
        ...new Set([
          ...entityFields,
          ...this.payload.other_entities.flatMap((e) => e.entity_id),
        ]),
      ];
      if (entityFields && entityFields.length) {
        this.loading = true;
        this.refresh = true;
        await Promise.all(
          entityFields.map(async (el) => {
            return await this.fetchEntityData(el);
          })
        );
        this.refresh = false;
        this.loading = false;
      }
    },
    mapAllSelectedFilters(filters) {
      return (filters || []).filter((e) => {
        if (e.operator == "in" && e.value && e.value.indexOf("all") != -1) {
          return false;
        }
        return true;
      });
    },
    async fetchEntitiesData(mainFilters, currentEntityDataids = []) {
      this.loading = true;
      this.data = {};
      let primaryFilterData = [];
      let index = -1;
      this.primaryFilter = this.payload.filter_fields.find(
        (ft) => ft.primary_filter
      );
      if (this.primaryFilter != undefined) {
        index = mainFilters?.findIndex(
          (ft) => ft.field == this.primaryFilter.key
        );
        primaryFilterData = [...(mainFilters[index]?.value || [])];
      }
      if (primaryFilterData.length) {
        await Promise.all(
          primaryFilterData.map(async (ftDt) => {
            let main_filters = JSON.parse(JSON.stringify(mainFilters));
            main_filters[index].value = [ftDt];
            this.resetPagination(ftDt);
            await this.fetchData(main_filters, currentEntityDataids, ftDt);
          })
        );
      } else {
        let filter = "NO_PRIMARY_FILTER";
        this.resetPagination(filter);
        await this.fetchData(mainFilters, currentEntityDataids, filter);
      }
      this.loading = false;
    },
    resetPagination(filter) {
      this.$set(this.paginationData, filter, {
        tablePage: 1,
        tableLimit: 20,
        tableDataPages: 1,
        loading: true,
        infiniteDisabled: true,
      });
    },
    setPaginations(filter, noReset = false) {
      this.$set(this.paginationData, filter, {
        tablePage:
          !noReset && this.isSearchingData
            ? 1
            : (this.paginationData?.[filter]?.tablePage || 0) + 1,
        tableLimit: this.paginationData?.[filter]?.tableLimit || 20,
        tableDataPages:
          !noReset && this.isSearchingData
            ? 1
            : this.paginationData?.[filter]?.tableDataPages || 1,
        loading: true,
        infiniteDisabled: true,
      });
    },
    async addRelationalFilters(filters) {
      let mainFilters = JSON.parse(JSON.stringify(filters));
      if (this.isApplicationUserSide && this.component?.is_relational_data) {
        let { activeWorkspace, currentActiveWorkspace } =
          this.getCurrentApplicationUserDetails();
        if (activeWorkspace && currentActiveWorkspace?.account_data_id) {
          if (!this.otherEntitiesAllFields[this.payload.entity_id]) {
            let currentEntity = await fetchEntityById(this.payload.entity_id);
            this.otherEntitiesAllFields[this.payload.entity_id] = [
              ...this.getAllFieldsFromEntity(
                currentEntity,
                false,
                false,
                true,
                true
              ),
            ];
          }
          let field = this.otherEntitiesAllFields[this.payload.entity_id].find(
            (e) => e.inputType == "ENTITY" && e.entity_id == activeWorkspace
          );
          if (field?.key) {
            let key = field.key.includes("#")
              ? field.key.split("#")[1]
              : field.key;
            let index = mainFilters.findIndex(
              (e) => e.field == field.template_id + "#" + key
            );
            if (index > -1) {
              mainFilters[index] = {
                ...mainFilters[index],
                ...{
                  value: [currentActiveWorkspace.account_data_id],
                },
              };
            } else {
              mainFilters.push({
                field: field.template_id + "#" + key,
                operator: "in",
                value: [currentActiveWorkspace.account_data_id],
                value_type: "",
                value_source: "",
                data_type: "OBJECT_ID",
                query_type: "AND",
                value_field: "",
                validations: {
                  data_type: "",
                },
                relation_entity_id: "",
                data_source: "ENTITY_DATA_LIST",
                main_entity_field: true,
                linked_entity_ids: [],
              });
            }
          }
        }
      }
      return mainFilters;
    },
    async fetchData(mainFilters, currentEntityDataids, filter) {
      if (this.checkIsDividedFilterAdded) {
        this.payload.main_columns = this.payload.main_columns.map((col) => {
          if (col.divide_data_by && this.payload?.rules) {
            let actionColumns = [];
            this.payload.rules.forEach((rule) => {
              rule.conditions.forEach((con) => {
                if (con?.column) {
                  actionColumns.push(con.column);
                }
              });
            });

            col.actionColumns = actionColumns;
            col.includeApprovalData = true;
          }
          return col;
        });
      }
      this.isSearchingData = false;
      mainFilters = await this.addRelationalFilters(mainFilters);
      let params = {
        entity_data_ids: currentEntityDataids,
        main_filters: this.mapAllSelectedFilters(mainFilters),
        limit: this.paginationData?.[filter]?.tableLimit,
        page: this.paginationData?.[filter]?.tablePage,
        // main_filters: mainFilters,
        ...this.payload,
        ...(this.search_string && {
          search_string: this.search_string,
        }),
      };
      const getEntityViewsData = await postAPICall(
        "POST",
        "/entities-data/entity/view/data",
        params
      );
      if (getEntityViewsData?.data) {
        // this.data = this.filterIgnoreFieldsData(getEntityViewsData.data);
        this.paginationData[filter].tableDataPages =
          getEntityViewsData.total_pages;
        this.entityViewData[filter] =
          this.paginationData[filter].tablePage > 1
            ? [
                ...(this.entityViewData[filter] || []),
                ...(getEntityViewsData.data || []),
              ]
            : getEntityViewsData.data || [];
        if (getEntityViewsData.selectedEntity) {
          this.selectedEntityFields = this.getAllFieldsFromEntity(
            getEntityViewsData.selectedEntity,
            false,
            false,
            true,
            true
          );
          this.currentEntity = { ...getEntityViewsData.selectedEntity };
          if (this.currentEntity?.templates) {
            this.currentEntity.templates.forEach((t) => {
              let temp = JSON.parse(JSON.stringify(t));
              if (temp?.templateInfo?.rules) {
                let colorRules = temp.templateInfo.rules.filter((e) => {
                  if (
                    e?.actions &&
                    e.actions.find((el) => el.operator_type == "CHANGE_COLOR")
                  ) {
                    return e;
                  }
                });
                if (colorRules && colorRules.length) {
                  let fields = [];
                  temp.templateInfo.sections.forEach((e) => {
                    fields = [...fields, ...e.fields];
                  });
                  this.entitytemplateColorRules[temp.template_id] = {
                    fields: fields,
                    colorRules: colorRules,
                  };
                }
              }
            });
          }
        }
        let data = this.filterIgnoreFieldsData(
          this.getDataTableData(this.entityViewData[filter])
        );
        this.data[filter] = [...data];
        // this.mapEntityDataWithUserInfo(data).then((e) => {
        //   this.entityUserInfoData = { ...this.entityUserInfoData, ...e };
        // });
        this.aggregationValues[filter] = {};
        this.fetchAggregationValues(filter);
        if (
          this.paginationData[filter].tablePage >=
          this.paginationData[filter].tableDataPages
        ) {
          this.paginationData[filter].loading = false;
        }
        this.$set(this.paginationData, filter, {
          ...this.paginationData[filter],
          ...{
            infiniteDisabled: false,
          },
        });
      } else {
        this.loading = false;
        this.paginationData[filter].loading = false;
        this.$set(this.paginationData, filter, {
          ...this.paginationData[filter],
          ...{
            infiniteDisabled: false,
          },
        });
      }
    },
    filterIgnoreFieldsData(data) {
      let ignoreColumns = this.payload.main_columns.filter(
        (e) => e.ignore_if_empty
      );
      if (ignoreColumns.length) {
        return data.filter((dt) => {
          let results = [];
          ignoreColumns.forEach((col) => {
            results.push(this.checkDataIgnored(dt, col));
          });
          if (results.every((el) => el)) {
            return true;
          }
          return false;
        });
      }
      return data;
    },
    checkDataIgnored(data, column) {
      let result = false;
      let key = this.getProp(column);
      if (data && data[key]) {
        result = true;
      }
      return result;
    },
    async backToEntitiesViews() {
      if (this.getEntityViewRedirectRoute) {
        this.$router.push({ path: this.getEntityViewRedirectRoute });
        this.$store.commit("entityViews/setEntityViewRedirectRoute", null);
      } else {
        let query = this.getNavigationQuery(this.$route.query);
        this.$router.push({ path: "/entity-views", query: query });
      }
    },

    getFieldStyle(column, data) {
      if (
        this.entitytemplateColorRules &&
        this.entitytemplateColorRules[column.template_id] &&
        this.entitytemplateColorRules[column.template_id]["colorRules"]
      ) {
        let colorRules = this.entitytemplateColorRules[column.template_id][
          "colorRules"
        ].filter((e) => {
          if (
            e?.actions &&
            e.actions.find(
              (el) =>
                el.operator_type == "CHANGE_COLOR" &&
                el.selectField == column.key
            )
          ) {
            return e;
          }
        });
        let style = this.checkEntityDataConditions(
          colorRules,
          data.entityData[column.template_id],
          this.entitytemplateColorRules[column.template_id]["fields"],
          this.payload.main_columns
        );
        return style;
      }
      return "";
    },
    getCurrencyFormat(value, field) {
      let label = "";
      if (field?.inputType == "CURRENCY") {
        let type = field.validations.currency
          ? field.validations.currency
          : "USD";
        let locale = field.validations.locale
          ? field.validations.locale
          : "en-US";
        label = new Intl.NumberFormat(locale, {
          style: "currency",
          currency: type,
        }).format(value);
      } else if (
        field?.inputType == "AGGREGATE_FUNCTION" ||
        field?.inputType == "FORMULA"
      ) {
        let isCurrencyField = (field.selected_fields || []).find((el) => {
          if (
            el?.inputType == "CURRENCY" ||
            el?.input_type == "CURRENCY" ||
            el?.result_type == "CURRENCY"
          ) {
            return true;
          }
        });
        if (
          !isCurrencyField &&
          field?.inputType == "FORMULA" &&
          field.result_type == "CURRENCY" &&
          field?.validations?.currency
        ) {
          let locale = field.validations?.locale || "en-US";
          return (label = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: field?.validations.currency,
          }).format(value));
        }
        if (isCurrencyField) {
          if (field?.inputType == "AGGREGATE_FUNCTION") {
            let dataTableColumn = this.getDataTableField(
              field.input_type == "ENTITY_VARIABLE"
                ? field.global_variable_entity_field_template_id
                : field.template_id,
              isCurrencyField.key
            );
            if (dataTableColumn) {
              if (dataTableColumn?.validations) {
                isCurrencyField["validations"] = dataTableColumn.validations;
              }
            }
          } else {
            if (field?.validations) {
              isCurrencyField["validations"] = field.validations;
            }
          }
          let type =
            isCurrencyField &&
            isCurrencyField.validations &&
            isCurrencyField.validations.currency
              ? isCurrencyField.validations.currency
              : "USD";
          let locale =
            isCurrencyField &&
            isCurrencyField.validations &&
            isCurrencyField.validations.locale
              ? isCurrencyField.validations.locale
              : "en-US";
          label = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: type,
          }).format(value);
        } else {
          label = value;
        }
      } else {
        label = value;
      }
      return label;
    },
    fetchAggregationValues(primary_filter) {
      (this.payload.aggregations || []).map((aggregation) => {
        if (aggregation.type == "Display") {
          let [key, filter] = aggregation.displayField.includes("@")
            ? aggregation.displayField.split("@")
            : [aggregation.displayField, ""];
          this.aggregationValues[primary_filter][aggregation.label] =
            this.computeAggregation(
              `${key.split("#")[1]}#${key.split("#")[2]}#${key.split("#")[3]}` +
                (![undefined, null, ""].includes(filter) ? "@" + filter : ""),
              key.split("#")[0].toUpperCase(),
              primary_filter
            ).toFixed(2);
        } else {
          let expr = "";
          aggregation.selected_fields.map((fd) => {
            if (fd.type == "FIELD") {
              let value = this.computeAggregation(
                `${fd.linked_entity_id}#${fd.template_id}#${fd.key}` +
                  (![undefined, null, ""].includes(fd?.filter)
                    ? "@" + fd?.filter
                    : ""),
                fd.agg_type,
                primary_filter
              );
              expr += value;
            } else {
              expr += fd.value;
            }
          });
          try {
            this.aggregationValues[primary_filter][aggregation.label] = mexp
              .eval(expr)
              .toFixed(2);
          } catch (err) {
            this.aggregationValues[primary_filter][aggregation.label] = "0.00";
          }
        }
        this.aggregationValues[primary_filter][aggregation.label] =
          this.getAggregationValue(aggregation, primary_filter);
      });
    },
    computeAggregation(field, type, filter) {
      let sum = 0;
      this.getData(this.data[filter]).map((dt) => {
        sum += Number(dt[field]);
      });
      if (type == "SUM") {
        return sum;
      } else {
        return Number(sum / this.getData(this.data[filter]).length);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-checkbox-group {
  display: flex;
  flex-direction: column;
  margin-left: 25px;
}

.dialog-checkbox-group .el-checkbox {
  margin-bottom: 8px;
}
.sel-content {
  font-size: 16px;
  font-weight: 500;
}
.sidemenu {
  max-height: 80vh;
  overflow-y: auto;
  scrollbar-width: thin;
}
.sidemenu ::v-deep .el-table__body-wrapper {
  height: auto !important;
  scrollbar-width: thin;
}
.outer-div {
  display: flex !important;
  justify-content: space-between !important;

  @media (max-width: 650px) {
    margin-top: 10px !important;
  }
}
.head-title {
  font-size: 18px;
}
.filters-cards {
  display: flex;
  grid-gap: 10px;
  margin-top: 10px;
  grid-template-columns: repeat(4, 1fr);
  align-items: end;
  margin: 10px !important;

  @media (max-width: 650px) {
    display: table-row !important;
  }
}

.entity-buttons {
  display: flex;
}

.reset-button {
  margin-left: 10px;
}

.run-button {
  font-size: 0.875rem;
}
.custom-table {
  width: 100%;
  border-collapse: collapse;
  font-size: xx-small;
}
.custom-table th {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
  color: black;
  font-weight: bold;
  font-size: x-small;
  background-color: #f2f2f2;
}
.custom-table td {
  border: 2px solid black;
  padding: 8px;
  text-align: left;
  color: black;
}
.custom-table thead {
  background-color: #e0e0e0;
}
.download-button {
  margin-top: -14px;
  font-size: 13px;
}
.download-button i {
  margin-left: 4px;
}
.d-flex {
  display: flex;
}
.ml-4 {
  margin-left: 16px;
}
.mt-2 {
  margin-top: 8px;
}
.el-divider {
  position: relative;
  top: 25px;
}
.fa {
  padding: 0px !important;
}
.fullscreen-button {
  margin-right: 10px !important;
}
</style>
